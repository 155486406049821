<div id="breadcrumb" [class.header-is-fixed]="fixed">
  <a routerLink="/home" [title]="'menu.home' | translate"><span class="link">/ {{ 'menu.home' | translate
      }}</span></a>
  <!-- <ng-container *ngFor="let breadcrumb of breadcrumbs$ | async; let last = last"> -->
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
    <ng-container *ngIf="!breadcrumb.hidden">
      <span *ngIf="!last && breadcrumb.url && breadcrumb.navigable; else lastCrumb">
        / <a [routerLink]="breadcrumb.url" [title]="breadcrumb.label"><span class="link">{{breadcrumb.label}}</span></a>
      </span>
      <ng-template #lastCrumb>
        <span> / {{ breadcrumb.label }}</span>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
<ul class="submenu inactive" [attr.status]="'inactive'" #submenu>
  <ng-container *ngFor="let child of items; let i=index;">
    <ng-container *ngIf="!child.hidden">
      <li *ngIf="child.children && child.children.length>0; else noChildren" class="has-children"
        [attr.status]="'inactive'" [class.separator]="child.separator">
        <div class="title" fxLayoutAlign="space-between center" fxLayoutGap="12px" (click)="onClickItem(i);"
          [style.paddingLeft]="level*12+'px'">
          <div fxLayoutAlign="start center" fxLayoutGap="8px">
            <span *ngIf="child.translate === undefined || child.translate">{{ translations['menu'][child.title]
              }}</span>
            <span *ngIf="child.translate === false">{{ child.title }}</span>
            <mat-icon *ngIf="child.private" class="private-icon">lock</mat-icon>
          </div>
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
        <menu-mobile-item [items]="child.children" [level]="level" (clickItem)="propagateAction($event)"
          (clickFinalItem)="onClickFinalItem()"></menu-mobile-item>
      </li>
    </ng-container>

    <ng-template #noChildren>
      <li *ngIf="!child.external" (click)="onClickFinalItem();" [class.separator]="child.separator">
        <a [routerLink]="child.url" [title]="child.translate === undefined || child.translate ? translations['menu'][child.title] : (child.translate === false ?
        child.title : '')">
          <div class="title" fxLayoutAlign="space-between center" fxLayoutGap="8px" [style.paddingLeft]="level*12+'px'">
            <span *ngIf="child.translate === undefined || child.translate">{{ translations['menu'][child.title]
              }}</span>
            <span *ngIf="child.translate === false">{{ child.title }}</span>
            <mat-icon *ngIf="child.private" class="private-icon">lock</mat-icon>
          </div>
        </a>
      </li>

      <li *ngIf="child.external" (click)="onClickFinalItem();">
        <a [href]="child.url" target="_blank" title="{{ translations['menu'][child.title] }}"
          [class.separator]="child.separator">
          <div class="title" fxLayoutAlign="space-between center" fxLayoutGap="8px" [style.paddingLeft]="level*12+'px'">
            <span *ngIf="child.translate === undefined || child.translate">{{ translations['menu'][child.title]
              }}</span>
            <span *ngIf="child.translate === false">{{ child.title }}</span>
            <mat-icon *ngIf="child.private" class="private-icon">lock</mat-icon>
          </div>
        </a>
      </li>
    </ng-template>

  </ng-container>
</ul>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'slug' })
export class SlugifyPipe implements PipeTransform {
  transform(input: string): string {
    return input.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/á/g, 'a')
      .replace(/à/g, 'a')
      .replace(/ä/g, 'a')
      .replace(/é/g, 'e')
      .replace(/è/g, 'e')
      .replace(/ë/g, 'e')
      .replace(/í/g, 'i')
      .replace(/ì/g, 'i')
      .replace(/ï/g, 'i')
      .replace(/ó/g, 'o')
      .replace(/ò/g, 'o')
      .replace(/ö/g, 'o')
      .replace(/ú/g, 'u')
      .replace(/ù/g, 'u')
      .replace(/ü/g, 'u')
      .replace(/Á/g, 'A')
      .replace(/É/g, 'E')
      .replace(/Í/g, 'I')
      .replace(/Ó/g, 'O')
      .replace(/Ú/g, 'U')
      .replace(/ñ/g, 'n')
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  }
}

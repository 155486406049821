import { OwlOptions } from 'ngx-owl-carousel-o';
import { invertColor } from '@web/shared/utils';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseWebComponent } from '../base-web/base-web.component';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '@web/base/shared/shared.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IResponse } from '@web/base/shared/models/response.model';
import { BaseListWebService } from './base-list-web.service';
import { IBaseListWebItem } from './base-list-web-item.model';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { OwlDOMData } from 'ngx-owl-carousel-o/lib/models/owlDOM-data.model';
import { SlugifyPipe } from '../../pipes/slugify.pipe';
import { Constants } from '../../constants.class';
import { AuthService } from '@web/base/auth/auth.service';

@Component({
  templateUrl: './base-list-web.component.html',
  styleUrls: ['./base-list-web.component.scss'],
  providers: [BaseListWebService, SlugifyPipe]
})
export class BaseListWebComponent extends BaseWebComponent implements OnInit {

  public invertColor = invertColor;

  DISPOSITION_LIST = ConstantsProject.DISPOSITION_LIST;
  DISPOSITION_GRID = ConstantsProject.DISPOSITION_GRID;
  DISPOSITION_JUMBOTRON = ConstantsProject.DISPOSITION_JUMBOTRON;
  DISPOSITION_CAROUSEL = ConstantsProject.DISPOSITION_CAROUSEL;

  itemKeyName: string;
  // categoryName: string;
  // categoryColor = '#999';
  showCategory = false;
  showSectionTitle = true;

  showImage = true;

  baseUrl: Array<string> = []; // Ruta base que se utilizará para preparar el enlace al detalle de los elementos.

  disposition: number = ConstantsProject.DISPOSITION_LIST;

  formFilter: UntypedFormGroup; // Formulario utilizado para editar/crear el elemento

  truncate = 400; // Longitud, por defecto, del texto del item a mostrar en el listado.
  paginate = true;
  filter = false;
  quantity = 50;
  total: number;
  page = 0;

  category; // Ítem que representa la categoría

  public items$: Subject<Array<IBaseListWebItem>> = new Subject<Array<IBaseListWebItem>>();

  public customOptions: OwlOptions;

  constructor(
    translate: TranslateService,
    public sanitizer: DomSanitizer,
    snackbar?: MatSnackBar,
    dialog?: MatDialog,
    public meta?: Meta,
    public title?: Title,
    public route?: ActivatedRoute,
    public sharedService?: SharedService,
    public baseListWebService?: BaseListWebService,
    public router?: Router,
    public slugPipe?: SlugifyPipe,
    public authService?: AuthService
  ) {
    super(translate, snackbar, dialog, meta, title, route, sharedService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.customOptions = {
      loop: true,
      mouseDrag: false,
      touchDrag: true,
      pullDrag: false,
      dots: true,
      navSpeed: 700,
      center: true,
      autoplay: true,
      items: 1,
      autoWidth: true
    };

    this.baseListWebService.setBase(this.section);
    if (this.filter) {
      this.createFormFilter();
    } else {
      this.getData();
    }
  }

  createFormFilter() {
    if (this.paginate) {
      this.formFilter = new UntypedFormGroup({
        page: new UntypedFormControl(0),
        quantity: new UntypedFormControl(this.quantity)
      });
    } else {
      this.formFilter = new UntypedFormGroup({});
    }

    this.getData(this.formFilter.value);
  }

  getData(filters?) {
    this.baseListWebService
      .load(filters, this.paginate, this.truncate)
      .subscribe(
        (response: IResponse) => {
          this.total = response.result.total;
          this.items$.next(
            response.result.items.map(i => {
              if (i.files) {
                const image = i.files.find(f => f.name === 'image');
                i.image = image && image.url ? image.url : null;

                // Para usar la imagen como background
                i.backgroundImg = i.image
                  ? this.sanitizer.bypassSecurityTrustStyle('url(' + i.image + ')')
                  : this.sanitizer.bypassSecurityTrustStyle('url(./../../../../../assets/img/default.jpg)');
              }

              return i;
            })
          );
        },
        err => {
          this.total = 0;
          this.items$.next([]);
        }
      );
  }

  loadPage(index: number) {
    this.formFilter.controls['page'].setValue(index - 1);
    this.page = index - 1;
    this.getData(this.formFilter.value);
  }

  // getPage(index: number) {
  //     this.loadPage.emit(index - 1);
  // }

  // Esto ahora se hace desde el html.

  // goToDetail(item: any) {
  //   this.router.navigate(this.baseUrl.concat([item[this.itemKeyName]], this.slugPipe.transform(item.title)));
  // }

  _copyUrl(url) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.openSnackbar(this.translations['general'].urlCopiedClipboard);
  }

}

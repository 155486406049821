import { SlugifyPipe } from '@web/base/shared/pipes/slugify.pipe';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@web/base/shared/components/base/base.component';
import { Constants } from '@web/base/shared/constants.class';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { URL_WEB } from './../../../../../environments/environment';
import { HttpResponse } from '@angular/common/http';
import { ContentsService } from '@web/base/web/contents/contents.service';
import { downloadFile } from '@web/base/shared/utils';

@Component({
  selector: 'web-share-toolbar',
  templateUrl: './share-toolbar.component.html',
  styleUrls: ['./share-toolbar.component.scss'],
  animations: [
    trigger('sharedAnimationState', [
      state('fadeOut', style({ width: 0, opacity: 0, 'pointer-events': 'none' })),
      state('fadeIn', style({ width: '205px', opacity: 1, 'pointer-events': 'initial' })),
      transition('fadeOut => fadeIn', animate('200ms ease-in')),
      transition('fadeIn => fadeOut', animate('200ms ease-out'))
    ]),
  ]
})
export class ShareToolbarComponent extends BaseComponent implements OnInit {

  @Input() content: any;

  sharedOptionsState = 'fadeOut';
  SHARED_FACEBOOK = Constants.SHARED_FACEBOOK;
  SHARED_WHATSAPP = Constants.SHARED_WHATSAPP;
  SHARED_TWITTER = Constants.SHARED_TWITTER;
  SHARED_LINKED_IN = Constants.SHARED_LINKED_IN;
  SHARED_EMAIL = Constants.SHARED_EMAIL;

  constructor(
    public translate: TranslateService,
    public contentsService: ContentsService,
    public router: Router,
    public slugPipe?: SlugifyPipe
  ) {
    super(translate);
  }

  ngOnInit() { }

  download(contentID) {
    this.contentsService
      .print(contentID)
      .subscribe((res: HttpResponse<Blob>) => {
        downloadFile(res, 'impresion-contenido-' + contentID + '.pdf');
      });
  }

  sendToPrinter(content) {
    const mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head><title>' + content.title + '</title>');
    mywindow.document.write('</head><body>');
    mywindow.document.write('<h3>' + content.title + '</h3>');
    mywindow.document.write(content.text);
    mywindow.document.write('</body></html>');

    mywindow.document.close();
    mywindow.focus();
    mywindow.print();
    mywindow.close();
  }

  toggleSharedOptions() {
    this.sharedOptionsState = this.sharedOptionsState === 'fadeOut' ? 'fadeIn' : 'fadeOut';
  }

  shared(platform, content) {

    const urlShared = URL_WEB.substring(0, URL_WEB.length - 1) + this.router.url;
    const logoCosital = URL_WEB.substring(0, URL_WEB.length - 1) + '/assets/img/logo_cosital_Network_10_aniversario.png';
    let shareNetwork;

    switch (platform) {
      case this.SHARED_FACEBOOK:
        shareNetwork = 'http://www.facebook.com/sharer.php?u=' + urlShared + '&t=' + this.slugPipe.transform(content.title) + '&p[images][0]=' + logoCosital;
        break;
      case this.SHARED_WHATSAPP:
        // shareNetwork = 'http://www.facebook.com/sharer.php?u=' + urlShared;
        shareNetwork = 'whatsapp://send?text=' + urlShared;
        break;
      case this.SHARED_TWITTER:
        shareNetwork = 'http://twitter.com/share?text=' + this.slugPipe.transform(content.title) + '&url=' + urlShared;
        break;
      case this.SHARED_LINKED_IN:
        shareNetwork = 'http://www.linkedin.com/shareArticle?mini=true&url=' + urlShared;
        break;
      case this.SHARED_EMAIL:
        shareNetwork = 'mailto:?subject=' + content.title + '&body=Echa un vistazo a este enlace ' + urlShared;
        break;
    }
    window.open(shareNetwork, 'sharer', 'toolbar=0,status=0,width=648,height=395');
  }
}

<div class="calendar-wrapper">

  <div class="loading-events material-shadow" *ngIf="loadingEvents">{{'general.loadingEvents'|translate}}</div>

  <div class="header-calendar">
    <div class="year">{{selectedDate | date:'yyyy'}}</div>
    <div class="date-complete" (click)="today()">{{selectedDate | date:'E dd MMMM'}}</div>
  </div>

  <mat-calendar #calendar [(selected)]="selectedDate" [(dateClass)]="dateClass" (selectedChange)="dateChanged()">
  </mat-calendar>

  <div *ngIf="eventsDay.length>0" class="events">
    <div class="separator"></div>
    <div class="event" *ngFor="let event of eventsDay" [style.borderLeftColor]="event.color" (click)="goToEvent(event)">
      <div class="day-event">
        <div class="truncate category" [style.color]="event.color" [title]="event.titleCategory">{{event.titleCategory}}
        </div>
        <div class="title">{{event.title}}</div>
        <div *ngIf="event.extra" class="extra">{{event.extra}}</div>
      </div>
    </div>
  </div>

</div>
<mat-sidenav-container>
  <mat-sidenav *ngIf="showTemplate" #sidenav mode="push" class="sidenav" fixedInViewport="true"
    (closedStart)="onSidenavClosedStart()" (openedStart)="onSidenavOpenedStart()">
    <div fxShow="true" fxShow.xs fxShow.sm>
      <web-menu-mobile></web-menu-mobile>
    </div>
  </mat-sidenav>

  <mat-sidenav-content [fxShow]="showContent">
    <web-header *ngIf="showTemplate"></web-header>
    <web-menu-header *ngIf="showTemplate" fxShow="false" fxShow.gt-sm></web-menu-header>
    <web-breadcrumb *ngIf="showTemplate"></web-breadcrumb>

    <!-- Container -->
    <div class="container">
      <div class="template-layout">

        <!-- Sidenav content -->
        <div class="left-sidenav" *ngIf="showSidenav && showTemplate">
          <web-sidenav></web-sidenav>
        </div>

        <!-- Web content -->
        <div class="web-content">
          <div class="loading" *ngIf="sharedService.loading">
            <mat-spinner [diameter]="32" [strokeWidth]="4"></mat-spinner>
          </div>
          <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>

      </div>
    </div>

    <web-footer *ngIf="showTemplate"></web-footer>
  </mat-sidenav-content>
</mat-sidenav-container>

<web-project-cookies-consent [showCookiesInfo]="showCookiesInfo" (acceptCookies)="showCookiesInfo=false"
  (openCookiesPolicy)="openCookiesPolicy()"></web-project-cookies-consent>
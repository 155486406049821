import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@web/base/shared/components/base/base.component';
import { Observable } from 'rxjs';
import { ConstantsProject } from '@web/project/shared/constants.class';

@Component({
  selector: 'web-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
})
export class AttachmentsComponent extends BaseComponent implements OnInit {

  @Input() attachments: Observable<Array<any>>;
  @Input() arrayAttachments: Array<any>;

  @Input() titleImages: string;
  @Input() titleVideos: string;
  @Input() titleAttachments: string;

  @Input() showTitles: boolean = true;
  @Input() classList: string;

  files: Array<any>;
  images: Array<any>;
  videos: Array<any>;

  constructor(
    public translate: TranslateService
  ) {
    super(translate);
  }

  ngOnInit() {

    console.log("entra: ", this.attachments);

    if (this.arrayAttachments) {
      this.files = this.arrayAttachments.filter(a => a.type === ConstantsProject.ATTACHMENT_TYPE_FILE);
      this.images = this.arrayAttachments.filter(a => a.type === ConstantsProject.ATTACHMENT_TYPE_IMAGE);
      this.videos = this.arrayAttachments.filter(a => a.type === ConstantsProject.ATTACHMENT_TYPE_VIDEO);

      // Ordenamos por el campo "orden"
      this.files = this.files.sort((e1, e2) => e1.order < e2.order ? -1 : 1);
      this.images = this.images.sort((e1, e2) => e1.order < e2.order ? -1 : 1);
      this.videos = this.videos.sort((e1, e2) => e1.order < e2.order ? -1 : 1);
    }
    else if (this.attachments) {
      this.subscriptions.push(this.attachments.subscribe(atts => {
        this.files = atts.filter(a => a.type === ConstantsProject.ATTACHMENT_TYPE_FILE);
        this.images = atts.filter(a => a.type === ConstantsProject.ATTACHMENT_TYPE_IMAGE);
        this.videos = atts.filter(a => a.type === ConstantsProject.ATTACHMENT_TYPE_VIDEO);
      }));

      // Ordenamos por el campo "orden"
      this.files = this.files.sort((e1, e2) => e1.order < e2.order ? -1 : 1);
      this.images = this.images.sort((e1, e2) => e1.order < e2.order ? -1 : 1);
      this.videos = this.videos.sort((e1, e2) => e1.order < e2.order ? -1 : 1);
    }
  }

}

<div class="section-title-dinamic">
  <div fxLayoutAlign="space-between center" fxLayoutGap="24px" class="width-100">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="12px">
      <mat-icon svgIcon="ico_observatorios"></mat-icon>
      <h2>{{title}}</h2>
    </div>
    <button mat-icon-button (click)="onClick()">
      <mat-icon fxShow="false" fxShow.xs fxShow.sm class="icon-menu">menu</mat-icon>
    </button>
  </div>
</div>
<div class="container">
  <div class="banners">
    <ng-container *ngFor="let banner of banners; let i=index">
      <div class="banner">
        <a [href]="banner.url" target="_blank" [title]="banner.title">
          <img [src]="banner.files[0].url">
        </a>
      </div>
    </ng-container>
  </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  // transform(value: string, limit, trail): string {
  //   limit = limit ? limit : 10;
  //   trail = trail ? trail : '...';
  //   return value.length > limit ? value.substring(0, limit) + trail : value;
  // }

  transform(value: string, limit, trail): string {

    // Comenzamos eliminando las etiquetas HTML
    let trimmedString = value.replace(/<\/?[^>]+>/gi, '');

    limit = limit || 10;
    trail = '';

    if (!trimmedString) {
      return '';
    } else if (trimmedString.length > limit) {
      trail = trail || '...';
      trimmedString = trimmedString.substr(0, limit);
      trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));
    }

    return trimmedString + trail;
  }
}

import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'web-section-title-dinamic',
  templateUrl: './section-title-dinamic.component.html',
  styleUrls: ['./section-title-dinamic.component.scss'],
})
export class SectionTitleDinamicComponent {

  @Input() title: string;
  @Output('openMenuClick') openMenuClick = new EventEmitter<boolean>();

  constructor(
    translate: TranslateService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('ico_observatorios', sanitizer.bypassSecurityTrustResourceUrl('./../../../assets/img/ico_observatorios.svg'));
  }

  onClick() {
    this.openMenuClick.emit(true);
  }
}

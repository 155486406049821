import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CollegiateUpdateComponent } from './collegiate-update/collegiate-update.component';

@Injectable()
export class TemplateDialogService {

  constructor(private dialog: MatDialog) { }

  openDialogCollegiateUpdate(): Observable<boolean> {
    let dialogRef: MatDialogRef<CollegiateUpdateComponent>;

    const config = new MatDialogConfig();
    config.panelClass = 'collegiate-update-dialog';
    config.disableClose = true;
    config.width = '95%';
    config.maxWidth = '500px';

    dialogRef = this.dialog.open(CollegiateUpdateComponent, config);

    return dialogRef.afterClosed();
  }
}

<div class="overlay-content" [@slideContent]="animationState" (@slideContent.start)="onAnimationStart($event)"
  (@slideContent.done)="onAnimationDone($event)">

  <div class="close" (click)="close()" *ngIf="showInterface" [@interface]>
    <mat-icon>close</mat-icon>
  </div>

  <div class="image-wrapper">
    <div class="gallery-spinner-wrapper" *ngIf="loading">
      <mat-spinner [strokeWidth]="4" [diameter]="48"></mat-spinner>
    </div>
    <img class="mat-elevation-z8" [style.opacity]="loading ? 0 : 1" [src]="image.url"
      [alt]="image.title ? image.title : 'genera.image' | translate" [@fade]="loading ? 'fadeOut' : 'fadeIn'"
      (load)="onLoadImage($event)" (click)="onClickImage();">
  </div>

  <div class="prev" (click)="previousImage()" *ngIf="hasNavigation && showInterface" [@interface]>
    <mat-icon>keyboard_arrow_left</mat-icon>
  </div>

  <div class="next" (click)="nextImage()" *ngIf="hasNavigation && showInterface" [@interface]>
    <mat-icon>keyboard_arrow_right</mat-icon>
  </div>

  <div class="description" *ngIf="!loading && showInterface" [@interface]>
    <div class="gallery-counter" *ngIf="hasNavigation && counter">{{'general.image'}} {{ imageIndex+1 }} /
      {{ gallery.length }}</div>
    {{ image.title }}
    <button *ngIf="actionButton" class="action-button" mat-raised-button [color]="actionButton.color"
      (click)="onClickActionButton()">{{ actionButton.title }}</button>
  </div>

</div>
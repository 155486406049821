import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import es from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpBackend } from '@angular/common/http';

registerLocaleData(es);

export function createTranslateLoader(http) {
  return new MultiTranslateHttpLoader(http, [
    "/assets/i18n/",
  ]);
}

@NgModule({
  bootstrap: [AppComponent],

  imports: [
    BrowserModule.withServerTransition({ appId: 'web-root' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [
          // HttpClient
          HttpBackend,
        ],
      },
    }),
    AppModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-ES' }
  ],
})
export class AppBrowserModule { }

<div class="videos-gallery" #videoGallery>
  <h4>{{ title }}</h4>

  <mat-grid-list cols="4" rowHeight="1:1" gutterSize="8px" #grid>
    <mat-grid-tile *ngFor="let video of videos; let i = index" (click)="loadVideo(video)">
      <div class="grid-video" [title]="!functionalCookies ? ('general.cookiesContentDisabled'|translate) : null">
        <img *ngIf="video.thumbnail" class="video" [class.disabled]="!functionalCookies" [src]="video.thumbnail"
          [alt]="video.title">
        <img *ngIf="!video.thumbnail" src="../../../../assets/img/video.svg" alt="ico" class="video"
          [class.disabled]="!functionalCookies">
        <mat-icon class="play-icon" [class.disabled]="!functionalCookies">play_arrow</mat-icon>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
<ng-template #noFile>
  <web-file-input (fileChange)="onFile($event)" [maxSize]="maxSizeAttachment" [acceptedFiles]="acceptedFiles">
    <button type="button" mat-stroked-button class="search-button">{{'general.searchDocument'|translate}}</button>
  </web-file-input>

</ng-template>

<div class="dialog">
  <div class="header" fxLayoutAlign="space-between center" fxLayoutGap="15px">
    <div>
      <h2 mat-dialog-title *ngIf="!attachments">{{'attachment.title' |translate}}</h2>
      <h2 mat-dialog-title *ngIf="attachments">{{'attachment.subtitleEdit' |translate}}</h2>
      <h3 *ngIf="!attachments">{{'attachment.subtitleNew' |translate}}</h3>
    </div>
  </div>

  <form [formGroup]="form">

    <mat-dialog-content>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px" class="content">

        <div *ngIf="form.controls['url'].value; else noFile" class="thumbnail">
          <web-file-input (fileChange)="onFile($event)" [maxSize]="maxSizeAttachment" [acceptedFiles]="acceptedFiles">
            <div fxLayoutAlign="start center" fxLayoutGap="24px" class="image-container">
              <div class="image">
                <img src="../../../../../../assets/img/document_attach.svg" class="center-block icon-document">
              </div>
              <div>
                <div class="filename" *ngIf="fileUploaded">
                  <div class="name">{{fileUploaded.name}}</div>
                  <div>{{fileUploaded.size / 1024 | number}} Kb</div>
                </div>
              </div>
              <button type="button" mat-fab class="btn-change-attachment" color="primary">
                <mat-icon>attachment</mat-icon>
              </button>
            </div>
          </web-file-input>
        </div>

        <mat-form-field>
          <mat-label>{{'attachment.name' | translate}}</mat-label>
          <input formControlName="title" matInput placeholder="{{'attachment.name' | translate}}" data-cy="title"
            required>
          <mat-error> {{'general.required' | translate}}</mat-error>
        </mat-form-field>

      </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayoutAlign="end center" fxLayoutGap="10px">
      <button mat-flat-button (click)="close()" class="cancel-button">{{'general.cancel' | translate}}</button>
      <web-loading-button (btnClick)="submit();" [loading]="false" data-cy="attachSend"></web-loading-button>
    </mat-dialog-actions>

  </form>

</div>
<div class="category-text text-content" *ngIf="category && category.text && category.text.length>0"
  [innerHTML]="category.text | safeHtml"></div>

<div class="divider" *ngIf="category && category.text && category.text.length>0"></div>

<div class="disposition-grid">
  <ng-container *ngIf="filter">
    <div class="item"
      *ngFor="let item of items$ | async | paginate: { id: 'registers', itemsPerPage: quantity, currentPage: (page + 1), totalItems: total }; let i = index"
      fxLayoutAlign="start stretch">
      <ng-container *ngTemplateOutlet="gridItemTemplate; context: {item: item, index: i}"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="!filter">
    <div *ngFor="let item of items$ | async; let i = index" class="item">
      <ng-container *ngTemplateOutlet="gridItemTemplate; context: {item: item, index: i}"></ng-container>
    </div>
  </ng-container>
</div>

<!-- Paginación -->
<div class="pagination" *ngIf="paginate">
  <pagination-controls class="web-pagination" (pageChange)="loadPage($event)"
    [previousLabel]="'general.previousPage' | translate" [nextLabel]="'general.nextPage' | translate"
    [responsive]="true" [autoHide]="true" id="registers"></pagination-controls>
</div>

<div *ngIf="(items$ | async)?.length==0">{{'general.noContent' | translate}}</div>

<!-- Template -->
<ng-template #gridItemTemplate let-item="item">
  <div class="item-content" fxLayout="column" fxLayoutAlign="start start">
    <div class="width-100">
      <!-- <div *ngIf="showImage && item.image">
                    <div class="image" [style.backgroundImage]="item.backgroundImg"></div>
                    <img *ngIf="item.image" class="image-xs" [src]="item.image" [alt]="item.title">
                </div> -->
      <div class="info">
        <div class="pre-header">
          <div class="date" *ngIf="item.date">{{item.date | date:'mediumDate'}}</div>
          <div class="observatory" *ngIf="item.observatoryName">{{item.observatoryName}}</div>
          <div class="category" *ngIf="showCategory">{{item.categoryName}}</div>
        </div>
        <img *ngIf="item.image" class="image" [src]="item.image" [alt]="item.title">
        <div class="title">{{item.title}}</div>
        <div class="text text-content" [innerHTML]="item.text|truncate:item.textTruncate"></div>
      </div>
    </div>
    <!-- <button mat-flat-button color="accent" class="more-info" (click)="goToDetail(item)">{{'general.readMore'|translate}}</button> -->
    <a [routerLink]="item.elementTypeID && item.elementTypeID === COVER_ELEMENT_TYPE_CONTENTS ? [('/' + item.url + '/' + item[itemKeyName] + '/' + (item.title | slug))] : item.url"
      mat-flat-button color="accent" class="more-info">{{'general.readMore'|translate}}</a>
  </div>
</ng-template>
<div class="disposition-carousel">
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let item of items$ | async; let i = index">
      <ng-template carouselSlide>
        <div class="item" [class.no-image]="!item.image" fxLayout.xs="column" fxLayoutAlign="start start"
          (click)="goToDetail(item)">
          <div *ngIf="showImage && item.image" class="image-container">
            <div fxHide="false" fxHide.xs class="image" [style.backgroundImage]="item.backgroundImg"></div>
            <img *ngIf="item.image" fxShow="false" fxShow.xs class="image" [src]="item.image" [alt]="item.title">
          </div>
          <div class="info">
            <div class="pre-header" fxLayoutAlign="start center">
              <div class="date" *ngIf="item.date">{{item.date | date:'mediumDate'}}</div>
              <div class="category" *ngIf="showCategory">{{item.categoryName}}</div>
            </div>
            <div class="title">{{item.title}}</div>
            <div class="text" [innerHTML]="item.text|truncate:200"></div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
import { CookiesService } from './../../base/core/cookies/cookies.service';
import { NgModule } from '@angular/core';
import { CoreModule } from '../../base/core/core.module';
import { CoreRoutingModule } from './core-routing.module';
import { HeaderComponent } from '@web/base/core/header/header.component';
import { RememberPasswordComponent } from '@web/base/core/header/dialogs/remember-password/remember-password.component';
import { SigninComponent } from '@web/base/core/header/dialogs/signin/signin.component';
import { FooterComponent } from '@web/base/core/footer/footer.component';
import { CookiesComponent } from '@web/base/core/cookies/cookies.component';
import { TemplateComponent } from '@web/base/core/template/template.component';
import { Error404Component } from '@web/base/core/error-404/error-404.component';
import { Error403Component } from '@web/base/core/error-403/error-403.component';

@NgModule({
  imports: [
    CoreModule,
    CoreRoutingModule
  ],
  exports: [
    HeaderComponent,
    RememberPasswordComponent,
    SigninComponent,
    FooterComponent,
    CookiesComponent,
    TemplateComponent,
    Error404Component,
    Error403Component,
  ],
  providers: [
    CookiesService
  ]
})
export class ProjectCoreModule { }

import { Component, OnInit, Input } from '@angular/core';
import { IAttachment } from './attachment.model';
import { order } from '@web/shared/utils';

@Component({
  selector: 'web-attachments-module',
  templateUrl: './attachments-module.component.html',
  styleUrls: ['./attachments-module.component.scss']
})
export class AttachmentsModuleComponent implements OnInit {

  @Input() attachments: IAttachment[];
  @Input() title: string;
  @Input() order: 'asc' | 'desc' = 'asc';

  constructor() { }

  ngOnInit() {
    if (this.order === 'asc') {
      this.attachments.sort((a, b) => order(a, b));
    } else {
      this.attachments.sort((a, b) => order(a, b, 'order', 'desc'));
    }
  }
}

<!-- <div #addThisScriptContainer></div>
<div #addThisDiv id="addThisDiv" class="addthis_toolbox addthis_default_style addthis_32x32_style">
    <a class="addthis_button_facebook"></a>
    <a class="addthis_button_twitter"></a>
    <a class="addthis_button_whatsapp"></a>
    <a class="addthis_button_compact"></a>
</div> -->

<!-- AddToAny BEGIN -->
<div fxLayout="row" class="addthis-block">
  <div class="a2a_kit a2a_kit_size_28 a2a_default_style">
    <a class="a2a_button_facebook"></a>
    <a class="a2a_button_x"></a>
    <a class="a2a_button_whatsapp"></a>
  </div>
  <div class="a2a_kit a2a_kit_size_28 a2a_default_style" data-a2a-icon-color="#FF6550">
    <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
  </div>
  <script>
    var a2a_config = a2a_config || {};
    a2a_config.locale = "es";
  </script>
  <div #addThisScriptContainer></div>
</div>
<!-- AddToAny END -->
<web-section-title title="{{'core.cookiesPolicy' | translate}}"></web-section-title>

<div class="cookies-text text-content">
  <h2>Definición y función genérica de las cookies</h2>
  El titular de este sitio web informa que se utilizan cookies y/o tecnologías similares que almacenan y recuperan
  información cuando navegas. En general, estas tecnologías pueden servir para finalidades muy diversas, como, por
  ejemplo, reconocerte como usuario, obtener información sobre tus hábitos de navegación, o personalizar la forma en que
  se muestra el contenido. Los usos concretos que hacemos de estas tecnologías se describen a continuación.

  <h2>Información sobre el tipo de cookies que se utilizan y su finalidad</h2>
  <ul>
    <li><strong>Técnicas:</strong> Son aquellas necesarias para la navegación y el buen funcionamiento de nuestro
      website.
      Permiten, por ejemplo, controlar el tráfico y la comunicación de datos, acceder a partes de acceso restringido,
      realizar el proceso de compra de un pedido, utilizar elementos de seguridad, almacenar contenidos para poder
      difundir vídeos o compartir contenidos a través de redes sociales.</li>
    <li><strong>Cookies funcionales:</strong> son necesarias para mostrar correctamente la página web/App y garantizar
      el correcto funcionamiento del sitio. Son cookies que ayudan al usuario a tener una mejor experiencia de la
      navegación por el sitio.</li>
    <li><strong>De personalización:</strong> Son aquéllas que permiten al Usuario acceder al servicio con unas
      características predefinidas en función de una serie de criterios, como por ejemplo el idioma, el tipo de
      navegador
      a través del cual se accede al servicio, la configuración regional desde donde se accede al servicio, etc.</li>
    <li><strong>De análisis:</strong> son aquellas que, tratadas por nosotros o por terceros, nos permiten cuantificar
      el
      número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del
      servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de
      productos o servicios que le ofrecemos.</li>
    <li><strong>Publicitarias:</strong> Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los
      espacios publicitarios quese pudieran incluir en nuestro website.</li>
    <li><strong>Publicitarias comportamentales:</strong> son aquellas que, tratadas por nosotros o por terceros, nos
      permiten analizar sus hábitos de navegación en Internet para que podamos mostrarle publicidad relacionada con su
      perfil de navegación.</li>
  </ul>

  <div class='table-container'>
    <table style='border-collapse: collapse;' width='100%' cellspacing='0' cellpadding='5' border='1'>
      <thead>
        <tr>
          <th>Denominación</th>
          <th>Titular</th>
          <th>Tipo</th>
          <th>Duración</th>
          <th>Descripción</th>
          <th>Más info</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>__CositalCookiesConsent</td>
          <td>Cosital</td>
          <td>Técnica</td>
          <td>Persistente</td>
          <td>Guarda la configuración de las cookies del usuario en esta web.</td>
          <td></td>
        </tr>

        <tr>
          <td>_gat</td>
          <td>Google</td>
          <td>Estadística</td>
          <td>Sesión</td>
          <td>Utilizado por Google Analytics para controlar la tasa de peticiones</td>
          <td rowspan='4'>
            <a href='https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage'
              target='_blank'>Google Analytics</a><br>
            <a href='http://www.google.com/intl/es/privacy.html' target='_blank'>Centro de privacidad de Google</a>
          </td>
        </tr>
        <tr>
          <td>_gid</td>
          <td>Google</td>
          <td>Estadística</td>
          <td>24 horas</td>
          <td>Se usa para distinguir a los usuarios</td>
        </tr>
        <tr>
          <td>_ga</td>
          <td>Google</td>
          <td>Estadística</td>
          <td>2 años</td>
          <td>Se usa para distinguir a los usuarios</td>
        </tr>
        <tr>
          <td>_ga_{{GOOGLE_ANALYTICS4_ID}}</td>
          <td>Google</td>
          <td>Estadística</td>
          <td>2 años</td>
          <td>Se utiliza para conservar el estado de la sesión</td>
        </tr>

        <tr>
          <td>__Cosital_popup_XXX</td>
          <td>Cosital</td>
          <td>Funcional</td>
          <td>Permanente / 7 días</td>
          <td>Matiene las opciones del usuario con respecto a la visibilidad de los anuncios emergentes mostrados por
            Cosital</td>
          <td>Cosital puede mostrar anuncios a través de una ventana emergente. El usuario puede decidir si ocultar el
            anuncio durante 7 días o si hacerlo de forma permantente.</td>
        </tr>

        <tr>
          <td>VISITOR_INFO1_LIVE</td>
          <td>Youtube</td>
          <td>Funcional</td>
          <td>179 días</td>
          <td>Intenta calcular el ancho de banda del usuario en páginas con vídeos de YouTube integrados</td>
          <td rowspan='2'>En ocasiones incrustamos vídeos de YouTube. El uso de cookies por parte de YouTube se rige por
            la política de privacidad de Google. Más información en <a
              href='http://www.google.es/intl/es/policies/technologies/types/' target='_blank'>Política de cookies de
              Youtube</a></td>
        </tr>
        <tr>
          <td>YSC</td>
          <td>Youtube</td>
          <td>Funcional</td>
          <td>Sesión</td>
          <td>Registra una identificación única para mantener estadísticas de qué vídeos de YouTube ha visto el usuario
          </td>
        </tr>

        <tr>
          <td>_ga_126VYLCXDY, __ssid, sd_client_id, _gcl_au, continuous_play_v3, _abexps, _ga, _fbp, player, vuid</td>
          <td>Vimeo</td>
          <td>Funcional</td>
          <td></td>
          <td>Estas cookies se instalan al mostrar cualquier video de Vimeo en una página. Aparecen bajo los dominios:
            av.vimeo.com, vimeo.com, player.vimeo.com y secure-a.vimeocdn.com</td>
          <td>En ocasiones incrustamos vídeos de Vimeo. El uso de cookies por parte de Vimeo se rige por la política de
            privacidad de Vimeo. Más información en <a href='https://vimeo.com/cookie_policy' target='_blank'>Política
              de cookies de Vimeo</a></td>
        </tr>

        <tr>
          <td>vvt</td>
          <td>shelf.bhybrid.com</td>
          <td>Funcional</td>
          <td>Sesión</td>
          <td>Esta cookie de sesión se instala únicamente al entrar en las Publicaciones como usuario suscriptor.</td>
          <td></td>
        </tr>

        <tr>
          <td>ouid</td>
          <td>Addthis</td>
          <td>Funcional</td>
          <td>Sesión</td>
          <td>Estas cookies tienen una finalidad publicitaria. Contienen un valor generado aleatoriamente único que
            permite a la
            Plataforma para distinguir navegadores y dispositivos. Esta información es utilizada para medir el
            rendimiento de
            los anuncios y proporcionar recomendaciones relativas a productos basadas en datos estadísticos.</td>
          <td rowspan="9">Usamos este plugin en la web para permitir a los visitantes compartir contenido. Addthis,
            además de
            invocar a las diferentes redes sociales, coloca sus propios cookies de gestión. Más información en la <a
              href="http://www.addthis.com/privacy" target="_blank">Política de Cookies de Addthis</a></td>
        </tr>
        <tr>
          <td>notice_gdpr_prefs, notice_preferences</td>
          <td>Addthis</td>
          <td>Funcional</td>
          <td>1 semana</td>
          <td>Funcionamiento interno de Addthis.</td>
        </tr>
        <tr>
          <td>na_id, na_tc</td>
          <td>Addthis</td>
          <td>Funcional</td>
          <td>2 años</td>
          <td>Funcionamiento interno de Addthis.</td>
        </tr>
        <tr>
          <td>loc</td>
          <td>Addthis</td>
          <td>Funcional</td>
          <td>1 año</td>
          <td>Geolocalización, que se utiliza para ayudar a los proveedores a determinar dónde están geográficamente
            ubicados (a
            nivel de estado) los usuarios que comparten información entre sí.</td>
        </tr>
        <tr>
          <td>uvc</td>
          <td>Addthis</td>
          <td>Funcional</td>
          <td>2 años</td>
          <td>Permite a los sitios web y a sus usuarios compartir fácilmente el contenido con los demás, a través de
            iconos de
            intercambio y de los destinos de bookmarking social. Las cookies Addthis se utilizan con el fin de habilitar
            el
            contenido para ser compartido. AddThis también se utiliza para recopilar información sobre cómo se comparte
            contenido del sitio web.</td>
        </tr>
        <tr>
          <td>uid</td>
          <td>Addthis</td>
          <td>Funcional</td>
          <td>2 meses</td>
          <td>Permite a los sitios web y a sus usuarios compartir fácilmente el contenido con los demás, a través de
            iconos de
            intercambio y de los destinos de bookmarking social. Las cookies Addthis se utilizan con el fin de habilitar
            el
            contenido para ser compartido. Addthis también se utiliza para recopilar información sobre cómo se comparte
            contenido del sitio web.</td>
        </tr>
        <tr>
          <td>ssc</td>
          <td>Addthis</td>
          <td>Funcional</td>
          <td>2 años</td>
          <td>Funcionamiento interno de Addthis.</td>
        </tr>
        <tr>
          <td>sshs, ssh</td>
          <td>Addthis</td>
          <td>Funcional</td>
          <td>2 años</td>
          <td>Funcionamiento interno de Addthis.</td>
        </tr>
      </tbody>
    </table>
  </div>

  <h2>Identificación de quién utiliza las cookies</h2>
  La información obtenida por las cookies es tratada solo por nuestro editor y/o también por terceros con el nuestro
  editor haya contratado la prestación de un servicio para el cual se requiera el uso de cookies:
  <ul>
    <li><strong>Cookies propias:</strong> Son aquellas que se envían a su equipo desde nuestros propios equipos o
      dominios
      y desde el que prestamos el servicio que nos solicita.</li>
    <li><strong>Cookies de terceros:</strong> Son aquellas que se envían a su equipo desde un equipo o dominio que no es
      gestionado por nosotros, sino por otra entidad colaboradora. Como, por ejemplo, las usadas por redes sociales, o
      por
      contenido externo como Google Maps, las que permiten cuantificar el número de usuarios y así realizar la medición
      y
      análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su
      navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.</li>
  </ul>

  <h2>Configuración del Navegador</h2>
  Puede usted permitir o bloquear las cookies, así como borrar sus datos de navegación (incluidas las cookies) desde el
  navegador que usted utiliza. Consulte las opciones e instrucciones que ofrece su navegador para ello. Tenga en cuenta
  que, si acepta las cookies de terceros, deberá eliminarlas desde las opciones del navegador.
  <br /><br />
  <strong>Internet Explorer:</strong> Herramientas &#8594; Opciones de Internet &#8594; Privacidad<br />
  <strong>Firefox:</strong> Herramientas &#8594; Opciones &#8594; Privacidad y Seguridad<br />
  <strong>Chrome:</strong> Configuración &#8594; Ajustes &#8594; Configuración avanzada &#8594; Privacidad y seguridad
  <br /><br />
  Adicionalmente, le informamos de las posibilidades de navegación privada. Muchos navegadores permiten activar un modo
  privado mediante el cual las cookies se borran siempre después de su visita. Dependiendo de cada navegador este modo
  privado, puede tener diferentes nombres. A continuación encontrará una lista de los navegadores más comunes y los
  diferentes nombres de este &ldquo;modo privado&rdquo;:
  <br /><br />
  <strong>Internet Explorer:</strong> 'Inprivate'<br />
  <strong>Google Chrome:</strong> 'Incógnito'<br />
  <strong>Firefox:</strong> 'Navegación Privada'<br />
  <br />
  El navegador también puede incluir la posibilidad de especificar mejor qué cookies tienen que ser aceptadas y cuáles
  no.
  En concreto, el usuario puede normalmente aceptar alguna de las siguientes opciones: rechazar las cookies de
  determinados dominios; rechazar las cookies de terceros; aceptar cookies como no persistentes (se eliminan cuando el
  navegador se cierra); permitir al servidor crear cookies para un dominio diferente. Además, los navegadores pueden
  también permitir a los usuarios ver y borrar cookies individualmente.
  <br /><br />
  Tenga en cuenta en todo caso que, si acepta las cookies de terceros, deberá eliminarlas desde las opciones del
  navegador
  o desde el sistema ofrecido por el propio tercero. Mas información para el borrado de cookies en los navegador más
  usados:
  <ul>
    <li><strong>Chrome:</strong> <a href='https://support.google.com/chrome/answer/95647?hl=es'
        target='_blank'>support.google.com/chrome/answer/95647?hl=es</a></li>
    <li><strong>Explorer:</strong> <a
        href='https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies'
        target='_blank'>support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a>
    </li>
    <li><strong>Firefox:</strong> <a
        href='https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias'
        target='_blank'>support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a>
    </li>
    <li><strong>Safari:</strong> <a href='https://support.apple.com/es-es/guide/safari/sfri11471/mac'
        target='_blank'>support.apple.com/es-es/guide/safari/sfri11471/mac</a></li>
  </ul>
  A continuación le mostramos mediante enlaces la forma de deshabilitar las cookies siguiendo las instrucciones de los
  navegadores más utilizados:
  <ul>
    <li><a href='http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies' target='_blank'>Internet
        Explorer</a></li>
    <li><a
        href='http://support.mozilla.org/en-us/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-us&amp;redirectslug=cookies'
        target='_blank'>Mozilla Firefox</a></li>
    <li><a href='https://support.google.com/chrome/answer/95647?hl=es' target='_blank'>Google Chrome</a></li>
  </ul>

  <h2>No realizamos transferencias de datos a terceros países realizadas por el editor.</h2>
  Puedes informarte de las transferencias a terceros países que, en su caso, realizan los terceros identificados en esta
  política de cookies en sus correspondientes políticas.

  <h2>Periodo de conservación de los datos</h2>
  Periodo de conservación de los datos para los diferentes fines en los términos establecidos en el artículo 13.2 a) del
  RGPD:
  <ul>
    <li><strong>Cookies de sesión:</strong> Son cookies temporales que permanecen en el archivo de cookies de su
      navegador
      hasta que el Usuario abandona la página web, por lo que ninguna queda registrada en el disco duro de su ordenador.
      La información obtenida por medio de estas cookies, sirven para analizar pautas de tráfico en el website. A la
      larga, esto nos permite proporcionar una mejor experiencia para mejorar el contenido y facilitar su uso.</li>
    <li><strong>Cookies persistentes:</strong> son almacenadas en el disco duro y nuestra web las lee cada vez que el
      Usuario realiza una nueva visita. Una cookie permanente posee una fecha de expiración determinada. La cookie
      dejará
      de funcionar después de esa fecha. Estas cookies las utilizamos, generalmente, para facilitar los servicios de
      compra y registro.</li>
  </ul>

  <h2>Qué ocurre si se deshabilitan las cookies</h2>
  Como le hemos indicado anteriormente, algunas funcionalidades de los servicios quedarán deshabilitados dependiendo del
  tipo de cookie. En definitiva, la navegación por nuestra web podría no ser óptima y algunos recursos estar
  deshabilitados.

  <h2>Actualizaciones y cambios</h2>
  El Titular puede modificar esta Política de Cookies en función de exigencias legislativas, reglamentarias, o con la
  finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos, por
  ello se aconseja a los usuarios que la visiten periódicamente.

  <h2>Retirar el consentimiento</h2>
  El usuario podrá retirar en cualquier momento su consentimiento relacionado con la Política de Cookies, y podrá
  eliminar
  las cookies almacenadas en su equipo a través de los ajustes y configuraciones de su navegador de Internet, indicados
  anteriormente, así como accediendo a nuestro panel de configuración:
  <br /><br />
  <button mat-flat-button color="accent" (click)="showCookiesConsent()">Configurar Cookies</button>
  <br /><br />
  La presente Política de Cookies se podrá modificar cuando así lo exija la legislación vigente en cada momento o cuando
  hubiera alguna variación en el tipo de cookies utilizadas en el sitio web. Por ello, le recomendamos revisar esta
  política cada vez que acceda a nuestro sitio web con el objetivo de estar adecuadamente informado sobre cómo y para
  qué usamos las cookies.
</div>
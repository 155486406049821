<web-home-slider-content [content$]="sliderContent$"></web-home-slider-content>

<!-- Secondary menu -->
<div class="secondary-menu" fxLayoutAlign="space-between stretch">
  <!-- <a  routerLink="/COVID-19/informacion" class="item-secondary-menu" fxLayout.gt-md="row" fxLayout="column"
    fxLayoutAlign="center center" fxLayoutGap="12px">
    <mat-icon svgIcon="ico_covid19"></mat-icon>
    <span>{{'home.covid'|translate}}</span>
  </a> -->
  <a routerLink="/dana/informacion" class="item-secondary-menu" fxLayout.gt-md="row" fxLayout="column"
    fxLayoutAlign="center center" fxLayoutGap="12px">
    <mat-icon svgIcon="ico_catastrofes"></mat-icon>
    <span>{{'home.dana'|translate}}</span>
  </a>
  <a routerLink="/fondos-comunitarios/informacion" class="item-secondary-menu" fxLayout.gt-md="row" fxLayout="column"
    fxLayoutAlign="center center" fxLayoutGap="12px">
    <mat-icon svgIcon="ico_fondos_eu"></mat-icon>
    <span>{{'home.communityFunds'|translate}}</span>
  </a>
  <a routerLink="/portales/11/portal-de-control-interno" class="item-secondary-menu" fxLayout.gt-md="row"
    fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
    <mat-icon svgIcon="ico_observatorios"></mat-icon>
    <span>{{'home.internalControl'|translate}}</span>
  </a>
  <!-- <a  routerLink="/p/44/observatorios-presentacion" class="item-secondary-menu" fxLayout.gt-md="row" fxLayout="column"
        fxLayoutAlign="center center" fxLayoutGap="12px">
        <mat-icon svgIcon="ico_observatorios"></mat-icon>
        <span>{{'home.observatories'|translate}}</span>
    </a> -->
  <!-- <a  routerLink="/consultas/presentacion" class="item-secondary-menu" fxLayout.gt-md="row" fxLayout="column"
        fxLayoutAlign="center center" fxLayoutGap="12px">
        <mat-icon svgIcon="ico_consultas"></mat-icon>
        <span>{{'home.queries'|translate}}</span>
    </a> -->
  <!-- <a  routerLink="/productos" class="item-secondary-menu" fxLayout.gt-md="row" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
        <mat-icon svgIcon="ico_tienda"></mat-icon>
        <span>{{'home.shopNetwork'|translate}}</span>
    </a> -->
  <!-- <a  routerLink="/contenidos/7301/formacion-descentralizada-2020" class="item-secondary-menu" fxLayout.gt-md="row" fxLayout="column"
    fxLayoutAlign="center center" fxLayoutGap="12px">
    <mat-icon svgIcon="ico_formacion_descentralizada"></mat-icon>
    <span>{{'home.descentralized'|translate}}</span>
  </a> -->
  <a routerLink="/cursos/3/descentralizada" class="item-secondary-menu" fxLayout.gt-md="row" fxLayout="column"
    fxLayoutAlign="center center" fxLayoutGap="12px">
    <mat-icon svgIcon="ico_formacion_descentralizada"></mat-icon>
    <span>{{'home.descentralized'|translate}}</span>
  </a>
  <a routerLink="/cursos/1/online" class="item-secondary-menu" fxLayout.gt-md="row" fxLayout="column"
    fxLayoutAlign="center center" fxLayoutGap="12px">
    <mat-icon svgIcon="ico_formacion_online"></mat-icon>
    <span>{{'home.online'|translate}}</span>
  </a>
  <a routerLink="/cursos/2/jornadas" class="item-secondary-menu" fxLayout.gt-md="row" fxLayout="column"
    fxLayoutAlign="center center" fxLayoutGap="12px">
    <mat-icon svgIcon="ico_formacion_presencial"></mat-icon>
    <span>{{'home.faceToFace'|translate}}</span>
  </a>
  <a (click)="handleToBanner('https://biblioteca.nubedelectura.com/cloudLibrary/ebook/list')" target="_blank"
    class="item-secondary-menu" fxLayout.gt-md="row" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
    <mat-icon svgIcon="ico_biblioteca"></mat-icon>
    <span>{{'home.library'|translate}}</span>
  </a>
</div>

<div class="container" fxLayoutAlign="start start">
  <div class="left-sidenav">
    <web-sidenav></web-sidenav>
  </div>
  <div class="contents">
    <!-- Novedades -->
    <div class="block news material-shadow">
      <div class="block-header" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayoutGap.xs="8px">
        <mat-icon svgIcon="ico_novedades"></mat-icon>
        <span class="title">{{'home.news'|translate}}</span>
        <div class="line"></div>
      </div>
      <div class="block-content">
        <web-home-grid-content [content$]="gridContent$"></web-home-grid-content>
        <div class="see-more">
          <button mat-flat-button color="primary" (click)="more(CONTENTS_NEWS)" [title]="'home.all'|translate">
            <div fxLayoutAlign="center center" fxLayoutGap="12px">
              <mat-icon>subject</mat-icon>
              <span>{{'general.viewMore' | translate}}</span>
            </div>
          </button>
        </div>
      </div>
    </div>

    <!-- Últimas actualizaciones de Observatorios y Portales -->
    <div class="block observatory-news material-shadow">
      <div class="block-header" fxLayoutAlign.gt-sm="start center" fxLayoutAlign="space-between center"
        fxLayoutGap="16px" fxLayoutGap.xs="8px">
        <div fxLayoutAlign="start center" fxLayoutGap="8px">
          <mat-icon svgIcon="ico_observatorios_novedades"></mat-icon>
          <span class="title">{{'home.latestUpdates'|translate}}</span>
        </div>
        <div class="line"></div>
      </div>
      <div class="block-content">
        <web-home-grid-content [content$]="contentsObservatoriesPortals$"></web-home-grid-content>
        <div class="see-more">
          <button mat-flat-button color="primary" (click)="more(CONTENTS_OBSERVATORIES_PORTALS)"
            [title]="'home.all'|translate">
            <div fxLayoutAlign="center center" fxLayoutGap="12px">
              <mat-icon>subject</mat-icon>
              <span>{{'general.viewMore' | translate}}</span>
            </div>
          </button>
        </div>
      </div>
    </div>

    <!-- Más artículos -->
    <div class="block more-articles material-shadow"
      [style.display]="(listContent$|async)?.length===0 ? 'none' : 'block'">
      <div class="block-header" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayoutGap.xs="8px">
        <mat-icon>subject</mat-icon>
        <span class="title">{{'home.moreArticles'|translate}}</span>
        <div class="line"></div>
      </div>
      <div class="block-content">
        <web-home-list-content [content$]="listContent$"></web-home-list-content>
      </div>
    </div>

    <!-- Consultas destacadas -->
    <div class="block featured-queries material-shadow">
      <div class="block-header" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayoutGap.xs="8px">
        <mat-icon svgIcon="ico_consultas_destacadas"></mat-icon>
        <span class="title">{{'home.featuredQueries'|translate}}</span>
        <div class="line"></div>
      </div>
      <div class="block-content">
        <div class="width-100" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <div *ngFor="let query of queries" class="query">
            <div>
              <div class="open" *ngIf="query.open">Abierta</div>
              <div class="category">{{'home.queryOf'|translate}} {{query.contactType}}</div>
              <div class="title">{{query.subject}}</div>
              <div class="text text-content">{{query.text | truncate:300}}</div>
            </div>
            <br />
            <a [routerLink]="query.url" [title]="'home.seeQuery'|translate">
              <button class="more-info" mat-flat-button color="accent">
                <div fxLayoutAlign="center center">
                  <span>{{'home.seeQuery'|translate}}</span>
                  <mat-icon>arrow_right</mat-icon>
                </div>
              </button>
            </a>
          </div>
        </div>
        <div class="see-more">
          <button mat-flat-button color="primary" (click)="more(CONTACTS_FEATURED)" [title]="'home.all'|translate">
            <div fxLayoutAlign="center center" fxLayoutGap="12px">
              <mat-icon>subject</mat-icon>
              <span>{{'general.viewMore' | translate}}</span>
            </div>
          </button>
        </div>
      </div>
    </div>

    <!-- Consultas mejor valoradas -->
    <div class="block featured-queries material-shadow">
      <div class="block-header" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayoutGap.xs="8px">
        <mat-icon svgIcon="ico_consultas_destacadas"></mat-icon>
        <span class="title">{{'home.ratingQueries'|translate}}</span>
        <div class="line"></div>
      </div>
      <div class="block-content">
        <div class="width-100" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <div *ngFor="let query of queriesBestRatings" class="query">
            <div>
              <div class="open" *ngIf="query.open">Abierta</div>
              <div class="category">{{'home.queryOf'|translate}} {{query.contactType}}</div>
              <div class="title">{{query.subject}}</div>
              <div class="text text-content">{{query.text | truncate:300}}</div>
            </div>
            <br />
            <a [routerLink]="query.url" [title]="'home.seeQuery'|translate">
              <button class="more-info" mat-flat-button color="accent">
                <div fxLayoutAlign="center center">
                  <span>{{'home.seeQuery'|translate}}</span>
                  <mat-icon>arrow_right</mat-icon>
                </div>
              </button>
            </a>
          </div>
        </div>
        <div class="see-more">
          <button mat-flat-button color="primary" (click)="more(CONTACTS_BEST_RATINGS)" [title]="'home.all'|translate">
            <div fxLayoutAlign="center center" fxLayoutGap="12px">
              <mat-icon>subject</mat-icon>
              <span>{{'general.viewMore' | translate}}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
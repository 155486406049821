<!-- Toolbar -->
<mat-toolbar [class.hidden-header]="scrollOffset > HEADER_HEIGHT">

  <div class="toolbar" fxLayoutAlign="space-between center" fxLayoutGap="24px">

    <div *ngIf="!isMobile" fxLayoutAlign="start center" fxLayoutGap="10px" class="title">
      <a routerLink="/home" title="COSITALnetwork">
        <img title="COSITALnetwork" src="../../../../assets/img/logo_xs.svg" width="24" height="24">
        <span class="toolbar-title">COSITALnetwork</span>
      </a>
    </div>

    <button *ngIf="isMobile" mat-icon-button (click)="openMenu()">
      <mat-icon>menu</mat-icon>
    </button>

    <div fxLayoutAlign="end center" fxLayoutGap="24px">

      <div class="search-block">

        <!-- Buscador -->
        <div *ngIf="!isMobile" class="header-toolbar-search-container"
          [class.hidden-header]="scrollOffset > HEADER_HEIGHT">
          <form [formGroup]="form" (submit)="resultSearch=null; searchAdvanced();">
            <mat-form-field class="search-input" floatLabel="never">
              <input formControlName="searchText" type="text" matInput placeholder="{{'general.search' | translate}}"
                #searchText>
              <mat-icon [class.show]="searchText.value.length>0" matSuffix class="close-search-button"
                (click)="searchText.value=''; resultSearch=null;">close</mat-icon>
            </mat-form-field>
            <button mat-icon-button (click)="resultSearch=null; searchAdvanced()"
              [disabled]="searchText.value.length<MIN_LENGTH_TO_SEARCH">
              <mat-icon>search</mat-icon>
            </button>
          </form>
        </div>

        <!-- Resultado de la búsqueda -->
        <ng-container *ngIf="resultSearch && resultSearch.length > 0">
          <div class="search-results" fxHide="false" fxHide.xs>
            <div class="result" *ngFor="let result of resultSearch" (click)="goToUrl(result.url)"
              fxLayoutAlign="start start" fxLayoutGap="20px">
              <a [routerLink]="result.url" [title]="result.title">
                <div class="image" *ngIf="result.image">
                  <img *ngIf="result.image" [src]="result.image" [alt]="result.title" class="img-responsive">
                </div>
                <div>
                  <div *ngIf="result.category" class="result-category">{{result.category}}</div>
                  <div *ngIf="result.location" class="result-location">{{result.location}}</div>
                  <div class="result-title">{{result.title}}</div>
                  <div class="result-date">{{result.createdAt|date:'mediumDate'}}</div>
                  <div *ngIf="result.description" class="result-description"
                    [innerHTML]="result.description|truncate:150|safeHtml"></div>
                  <div *ngIf="result.price" class="price">{{result.price | currency:'EUR':'symbol':'1.2-2'}}</div>
                </div>
              </a>
            </div>
          </div>
        </ng-container>

      </div>

      <!-- Logged -->
      <div *ngIf="authService.isLoggedIn() && authService.user" class="user-info">
        <div fxLayoutAlign="end center" fxLayoutGap="10px" [matMenuTriggerFor]="userMenu">
          <mat-icon>person</mat-icon>
          <span fxHide="false" fxHide.xs>{{ authService.user.name }} </span>
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
        <mat-menu #userMenu="matMenu" class="user-menu" xPosition="before" overlapTrigger="true">
          <div class="user-header">
            <div class="name">{{ authService.user.name }}</div>
          </div>

          <a mat-menu-item routerLink="/usuarios/editar" title="{{'core.menuPrivate.editUser' | translate}}">
            <mat-icon>edit</mat-icon> {{'core.menuPrivate.editUser' | translate}}
          </a>

          <a mat-menu-item routerLink="/usuarios/lista-direcciones"
            title="{{'core.menuPrivate.addresses' | translate}}">
            <mat-icon>euro_symbol</mat-icon> {{'core.menuPrivate.addresses' | translate}}
          </a>

          <a mat-menu-item routerLink="/usuarios/lista-suscripciones"
            title="{{'core.menuPrivate.subscriptions' | translate}}">
            <mat-icon>loyalty</mat-icon> {{'core.menuPrivate.subscriptions' | translate}}
          </a>

          <a mat-menu-item routerLink="/usuarios/pedidos" title="{{'core.menuPrivate.purchases' | translate}}">
            <mat-icon>local_mall</mat-icon> {{'core.menuPrivate.purchases' | translate}}
          </a>

          <a mat-menu-item routerLink="/usuarios/renovaciones" title="{{'core.menuPrivate.renovations' | translate}}">
            <mat-icon>sync</mat-icon> {{'core.menuPrivate.renovations' | translate}}
          </a>

          <a mat-menu-item routerLink="/usuarios/cambio-password" title="{{'core.menuPrivate.changePass' | translate}}">
            <mat-icon>lock</mat-icon> {{'core.menuPrivate.changePass' | translate}}
          </a>

          <div class="divider"></div>
          <button mat-menu-item fxLayoutAlign="start center" (click)="authService.logout()">
            <mat-icon>exit_to_app</mat-icon> {{'core.menuPrivate.signout' | translate}}
          </button>

        </mat-menu>
      </div>

      <!-- Login -->
      <div *ngIf="!authService.isLoggedIn() || !authService.user" class="user-login" fxLayoutAlign="end center"
        fxLayoutGap="12px" (click)="signinService.openDialog()">
        <span>{{'core.signin.login' | translate}}</span>
        <mat-icon>person</mat-icon>
      </div>

      <!-- Subscriber -->
      <div *ngIf="!authService.isLoggedIn() || !authService.user" class="subscribe-link" fxLayoutAlign="end center"
        fxLayoutGap="12px">
        <a routerLink="/informacion/suscripciones" [title]="'core.signin.becomeSubscriber' | translate"
          class="subscriber-button">
          <span fxHide="false" fxHide.xs>{{'core.signin.becomeSubscriber' | translate}}</span>
          <span fxShow="false" fxShow.xs>{{'core.signin.becomeSubscriberXS' | translate}}</span>
        </a>
      </div>
    </div>

  </div>

</mat-toolbar>

<!-- Header -->
<header class="header">
  <div class="header-content" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="space-between center">
    <div class="logo-container">
      <a routerLink="/home" title="home">
        <img src="./../../../../assets/img/logo_cosital_Network_10_aniversario.png" class="logo">
      </a>
      <!-- <img src="./../../../../assets/img/crespon.png" class="crespon"> -->
    </div>
    <div class="info">
      <div class="title" [innerHTML]="'home.title'|translate" fxHide.xs></div>
      <address class="address">
        <a class="email" href="mailto:cositalnetwork@cosital.es">cositalnetwork&#64;cosital.es</a>
        <div class="social">
          <a href="https://twitter.com/Cositalnetwork" title="X" target="_blank">
            <span class="icon-x"></span>
          </a>
          <a href="https://www.facebook.com/pages/Cositalnetwork/371811616242353?ref=stream" title="facebook"
            target="_blank">
            <span class="icon-facebook"></span>
          </a>
          <a href="https://t.me/joinchat/AAAAAFZDu7pxB2Zxtq3B9w" title="telegram" target="_blank">
            <span class="icon-telegram"></span>
          </a>
        </div>
      </address>

      <div fxLayoutAlign="end center" fxLayoutAlign.xs="center center" fxLayoutGap="24px">
        <a mat-raised-button class="btn-products" routerLink="/productos" title="{{'home.shop'|translate}}">
          <div class="button-inner">
            <img src="./../../../../assets/img/ico_tienda_network.svg">
            <span>{{'home.shop'|translate}}&nbsp;{{'home.network'|translate}}</span>
          </div>
        </a>
        <div class="social" fxShow="false" fxShow.xs>
          <a href="https://twitter.com/Cositalnetwork" title="X" target="_blank">
            <span class="icon-x"></span>
          </a>
          <a href="https://www.facebook.com/pages/Cositalnetwork/371811616242353?ref=stream" title="facebook"
            target="_blank">
            <span class="icon-facebook"></span>
          </a>
          <a href="https://t.me/joinchat/AAAAAFZDu7pxB2Zxtq3B9w" title="telegram" target="_blank">
            <span class="icon-telegram"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="sidenav-container" fxLayout.gt-md="column" fxLayout.md="row">
  <div>
    <web-calendar [events$]="events$" [loadingEvents$]="loadingEvents$"
      (dateSelected)="dateSelected($event)"></web-calendar>
    <div class="social" fxLayoutAlign="center center" fxLayoutGap="8px">
      <a href="https://twitter.com/Cositalnetwork" title="X" target="_blank">
        <span class="icon-x"></span>
      </a>
      <a href="https://www.facebook.com/pages/Cositalnetwork/371811616242353?ref=stream" title="facebook"
        target="_blank">
        <span class="icon-facebook"></span>
      </a>
      <a href="https://t.me/joinchat/AAAAAFZDu7pxB2Zxtq3B9w" title="telegram" target="_blank">
        <span class="icon-telegram"></span>
      </a>
    </div>
  </div>
  <web-banners-sidenav class="banners"></web-banners-sidenav>
</div>
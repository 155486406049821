import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class HandleHttpResponseInterceptor implements HttpInterceptor {

  constructor(public router: Router, public activatedRoute: ActivatedRoute) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req)
      .pipe(
        map(event => {
          return event;
        }),
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse) {
            const urlParts = req.url.split('/');

            switch (error.status) {

              case 401:
                console.log(error.statusText);

                // Para prevenir la concatenación del parámetro "returnUrl" a una url previa que ya llevaba ese parámetro
                // Marcos - 28/06/2023 - Si estoy llamando al login, no mando al login.
                if (urlParts[urlParts.length - 1] !== 'login') {
                  this.router.navigate(['login'], { queryParams: { returnUrl: this.activatedRoute.snapshot.queryParams['returnUrl'] } });
                  // this.router.navigate(['login'], { queryParams: { returnUrl: this.router.url } });
                }
                break;

              case 403:
                console.log(error.statusText);

                // Para prevenir la concatenación del parámetro "returnUrl" a una url previa que ya llevaba ese parámetro
                // Marcos - 28/06/2023 - Si estoy llamando al login, no mando al login.
                if (urlParts[urlParts.length - 1] !== 'login') {
                  this.router.navigate(['login'], { queryParams: { returnUrl: this.activatedRoute.snapshot.queryParams['returnUrl'] } });
                  // this.router.navigate(['login'], { queryParams: { returnUrl: this.router.url } });
                }
                break;

              case 404:
                console.log(error.statusText);
                this.router.navigate(['404']);
                break;

              default:
                console.log(error);
                break;
            }

            // return Observable.throw(error);
          }
          return throwError(error);
        })
      );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL } from '../../../../environments/environment';

@Injectable()
export class HeaderService {

  constructor(
    private http: HttpClient
  ) { }

  loadSearch(searchText: Text) {
    const url = `${URL}/search/find`;
    return this.http.post(url, { filter: searchText });
  }
}

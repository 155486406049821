<ul class="menu-web">
  <ng-container *ngFor="let item of menuSidenav">
    <ng-container *ngIf="!item.hidden">
      <li *ngIf="item.children && item.children.length>0; else noChildrenItem" style="min-width:200px !important"
        [class.has-image]="item.image">
        <div fxLayoutAlign="space-between center">
          <div class="first-level" [class.private]="item.private" fxLayoutAlign="start center" fxLayoutGap="10px">
            <img *ngIf="item.image && (!item.imagePosition || item.imagePosition==='left')"
              src="../../../../../assets/img/menu/{{item.image}}" class="image-item">
            <span *ngIf="item.translate === undefined || item.translate">{{ translations['menu'][item.title] }}</span>
            <span *ngIf="item.translate === false">{{ item.title }}</span>
            <img *ngIf="item.image && item.imagePosition==='right'" src="../../../../../assets/img/menu/{{item.image}}"
              class="image-item">
            <mat-icon *ngIf="item.private" class="private-icon">lock_outline</mat-icon>
          </div>
          <mat-icon class="menu-arrow">play_arrow</mat-icon>
        </div>
        <menu-sidenav-item [items]="item.children"></menu-sidenav-item>
      </li>
    </ng-container>

    <ng-template #noChildrenItem>
      <li *ngIf="!item.external" style="min-width:200px !important" [class.has-image]="item.image">
        <a routerLink="{{ item.url }}"
          [title]="item.translate === undefined || item.translate ? translations['menu'][item.title] : (item.translate === false ? item.title : '')">
          <div class="first-level" [class.private]="item.private" fxLayoutAlign="start center" fxLayoutGap="10px">
            <img *ngIf="item.image" src="../../../../../assets/img/menu/{{item.image}}" class="image-item">
            <span *ngIf="item.translate === undefined || item.translate">{{ translations['menu'][item.title] }}</span>
            <span *ngIf="item.translate === false">{{ item.title }}</span>
            <mat-icon *ngIf="item.private" class="private-icon">lock_outline</mat-icon>
          </div>
        </a>
      </li>

      <li *ngIf="item.external" style="min-width:200px !important" [class.has-image]="item.image">
        <a [href]="item.url" target="_blank" title="{{ translations['menu'][item.title] }}">
          <div class="first-level" [class.private]="item.private" fxLayoutAlign="start center" fxLayoutGap="10px">
            <img *ngIf="item.image" src="../../../../../assets/img/menu/{{item.image}}" class="image-item">
            <span *ngIf="item.translate === undefined || item.translate">{{ translations['menu'][item.title] }}</span>
            <span *ngIf="item.translate === false">{{ item.title }}</span>
            <mat-icon *ngIf="item.private" class="private-icon">lock_outline</mat-icon>
          </div>
        </a>
      </li>
    </ng-template>

  </ng-container>
</ul>
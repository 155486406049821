import { Component, forwardRef, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, UntypedFormControl, Validators } from '@angular/forms';
import { Time } from './time.model';

@Component({
  selector: 'web-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  // providers: [{ provide: MatFormFieldControl, useExisting: TimeInputComponent }],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeInputComponent),
      multi: true
    },
    {
      /**
       * IMPORTANTE -- Aquí definimos quien realiza la validación. En nuestro caso, TimeInputComponent,
       * porque implementa la función "validate".
       * Podría ser otra clase cualquiera que implementara esta función.
       */
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimeInputComponent),
      multi: true,
    }
  ]
})
export class TimeInputComponent implements OnInit, ControlValueAccessor {

  @Input('placeholder') placeholder: string;

  form: UntypedFormGroup;

  onChange = (_: any) => { };
  onTouch = () => { };

  constructor() { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      hour: new UntypedFormControl('00', [Validators.required]),
      minutes: new UntypedFormControl('00', [Validators.required])
    });

    this.form.valueChanges.subscribe((val: Time) => {
      this.checkValues();
      console.log('>>>', val.hour, val.minutes);

      this.onChange(val.hour + ':' + val.minutes);
    });
  }

  writeValue(value: any): void {
    if (value && typeof value === 'string') {
      const parts = value.split(':');
      value = { hour: parts[0], minutes: parts[1] };
      this.form.setValue(value);
    } else if (value === null) {
      this.form.setValue({ hour: '00', minutes: '00' });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validate(g: UntypedFormGroup) {
    if (g.valid) {
      return null;
    }

    return { required: true };
  }

  checkValues() {
    const regex = /^[0-9]*$/;
    const n = this.form.value;
    if (+n.hour > 23) { this.form.setValue({ hour: '23', minutes: n.minutes }, { emitEvent: false }); }
    if (+n.minutes > 59) { this.form.setValue({ hour: n.hour, minutes: '59' }, { emitEvent: false }); }
    // if (n.hour.length < 2) { this.form.setValue({ hour: '0' + n.hour, minutes: n.minutes }, { emitEvent: false }); }
    // if (n.minutes.length < 2) { this.form.setValue({ hour: n.hour, minutes: '0' + n.minutes }, { emitEvent: false }); }
    if (!regex.test(n.hour)) { this.form.setValue({ hour: '00', minutes: n.minutes }, { emitEvent: false }); }
    if (!regex.test(n.minutes)) { this.form.setValue({ hour: n.hour, minutes: '00' }, { emitEvent: false }); }
  }

  onBlur(field: string) {
    const n = this.form.value[field];
    if (n.length < 2) { this.form.controls[field].setValue('0' + n, { emitEvent: false }); }

    const values = this.form.value;
    this.onChange(values.hour + ':' + values.minutes);
  }

  sumHour() {
    const n = this.form.value;
    if (n.hour < 23) {
      n.hour = parseInt(n.hour, 10) + 1;
      n.hour = n.hour < 10 ? '0' + n.hour : '' + n.hour;
      this.form.setValue({ hour: n.hour, minutes: n.minutes });
    } else {
      this.form.setValue({ hour: '00', minutes: n.minutes });
    }
  }

  restHour() {
    const n = this.form.value;
    if (n.hour > 0) {
      n.hour = parseInt(n.hour, 10) - 1;
      n.hour = n.hour < 10 ? '0' + n.hour : '' + n.hour;
      this.form.setValue({ hour: n.hour, minutes: n.minutes });
    } else {
      this.form.setValue({ hour: '23', minutes: n.minutes });
    }
  }

  sumMinutes() {
    const n = this.form.value;
    if (n.minutes < 59) {
      n.minutes = parseInt(n.minutes, 10) + 1;
      n.minutes = n.minutes < 10 ? '0' + n.minutes : '' + n.minutes;
      this.form.setValue({ hour: n.hour, minutes: n.minutes });
    } else {
      this.form.setValue({ hour: n.hour, minutes: '00' });
    }
  }

  restMinutes() {
    const n = this.form.value;
    if (n.minutes > 0) {
      n.minutes = parseInt(n.minutes, 10) - 1;
      n.minutes = n.minutes < 10 ? '0' + n.minutes : '' + n.minutes;
      this.form.setValue({ hour: n.hour, minutes: n.minutes });
    } else {
      this.form.setValue({ hour: n.hour, minutes: '59' });
    }
  }

  keyDown(event: KeyboardEvent, type) {
    // up 38 down 40

    if (event.keyCode === 40) {
      if (type === 'hour') {
        this.restHour();
      } else if (type === 'minutes') {
        this.restMinutes();
      }
    } else if (event.keyCode === 38) {
      if (type === 'hour') {
        this.sumHour();
      } else if (type === 'minutes') {
        this.sumMinutes();
      }
    }
  }


}

<footer>
  <div class="college-footer">
    <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutAlign.gt-sm="space-between start" fxLayoutAlign="center center"
      fxLayoutGap.gt-sm="64px" fxLayoutGap="24px">

      <div>
        <img src="../../../../assets/img/logo_blanco.svg" class="logo">
        <div class="copyright">Copyright 2019 © COSITALNETWORK - Todos los derechos reservados</div>
      </div>

      <div>
        <div class="info">
          <div class="title">Secretarios, Interventores y Tesoreros<br />de Administración Local</div>
          <address>
            C/ Carretas 14 - 28012 Madrid<br />
            Teléfono: 915 211 825<br />
            <a href="mailto:cositalnetwork@cosital.es">cositalnetwork&#64;cosital.es</a>
          </address>
        </div>
        <div class="social">
          <a href="https://twitter.com/Cositalnetwork" title="X" target="_blank">
            <span class="icon-x"></span>
          </a>
          <a href="https://www.facebook.com/pages/Cositalnetwork/371811616242353?ref=stream" title="facebook"
            target="_blank">
            <span class="icon-facebook"></span>
          </a>
          <a href="https://t.me/joinchat/AAAAAFZDu7pxB2Zxtq3B9w" title="telegram" target="_blank">
            <span class="icon-telegram"></span>
          </a>
        </div>
      </div>

    </div>

  </div>
  <div class="vf-footer">
    <div class="container" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center"
      fxLayoutGap.xs="24px">
      <div>
        {{'core.developedBy' | translate}} <a href="http://www.viafisio.com/" target="_blank"
          title="Viafisio">Viafisio</a>
      </div>
      <div class="legal" fxLayoutAlign="end center" fxLayoutGap="10px">
        <button class="footer-button" (click)="openDataProtection()">{{'core.dataProtection' | translate}}</button>
        <!-- <button class="footer-button" (click)="openCookiesPolicy()">{{'core.cookiesPolicy' | translate}}</button> -->
        <a href="cookies" class="footer-button">{{'core.cookiesPolicy' | translate}}</a>
        <button class="footer-button" (click)="openLegalWarning()">{{'core.legalWarning' | translate}}</button>
      </div>
    </div>
  </div>
</footer>
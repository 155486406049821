import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { Injectable } from '@angular/core';

import { URL } from '../../../../environments/environment';
import { handleRequest } from '../utils';

@Injectable()
export class HandleHttpRequestInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const newBody = {};
    const body = req.body;

    let resBody;
    resBody = handleRequest(body, newBody);

    let locale = localStorage.getItem('locale');
    if (!locale || locale === 'es') {
      locale = ConstantsProject.LANGUAGES.find(e => e.default = true).isoCode;
    }

    let changedReq;

    // Si se trata de una llamada a la API, añadimos nuestras cabeceras; en caso contrario, no.
    if (req.url.substr(0, URL.length) !== URL) {
      changedReq = req.clone();
    } else {
      changedReq = req.clone({
        headers: req.headers
          .set('langID', ConstantsProject.LANGUAGES.find(e => e.isoCode === locale).value.toString())
      });

      changedReq = changedReq.clone({
        body: resBody
      });

      if (localStorage.getItem(ConstantsProject.TOKEN_KEY) && !changedReq.headers.has('skip-auth')) {
        changedReq = changedReq.clone({
          headers: changedReq.headers
            .set('Authorization', localStorage.getItem(ConstantsProject.TOKEN_KEY))
        });
      }

      if (changedReq.method === 'GET') {
        changedReq = changedReq.clone({
          headers: changedReq.headers.set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache')
        });
      }
    }

    return next.handle(changedReq);
  }
}

<ng-template #noAttachments>
  <div class="no-attachments">
    {{'attachment.noAttachments' | translate}}
  </div>
</ng-template>

<div class="attach-content">
  <div class="attach-header" fxLayoutAlign="space-between center">
    <div>
      <div fxLayoutAlign="start center" fxLayoutGap="12px">
        <span class="title" *ngIf="attachments && attachments.length>0" matBadge="{{attachments.length}}"
          matBadgeOverlap="false" matBadgeSize="small">{{'attachments.title' | translate}}</span>
        <span class="title" *ngIf="!attachments || (attachments && attachments.length<=0)">{{'attachments.title' |
          translate}}</span>
      </div>
      <div class="subtitle">
        <div class="max-files" *ngIf="maxFiles">{{'attachments.maxFiles'|translate:{maxFiles: maxFiles} }}</div>
        <div class="extra-info">
          <span [innerHtml]="'attachments.filesSize'|translate: {maxSize: maxSizeReadable}"></span>
          <span *ngIf="acceptedFilesReadable"
            [innerHtml]="'attachments.acceptedFiles'|translate: {acceptedFiles: acceptedFilesReadable}"></span>.
        </div>
      </div>
    </div>
    <button *ngIf="maxFiles === undefined || attachments.length < maxFiles" mat-mini-fab color="accent"
      (click)="openDialog(); $event.stopPropagation()" data-cy="attach">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <div class="attachment-list">
    <mat-list *ngIf="attachments && attachments.length>0; else noAttachments">
      <mat-list-item *ngFor="let attachment of attachments; let i = index"
        [ngClass]="{delete: attachment.delete, pending: !attachment.attachmentID || attachment.pending}"
        (click)="openDialog(i, attachment);$event.stopPropagation();">
        <mat-icon mat-list-icon *ngIf="canActive && attachment.active" class="is-active-icon">check</mat-icon>
        <mat-icon mat-list-icon *ngIf="canActive && !attachment.active"></mat-icon>

        <div matLine class="item" fxLayoutAlign="space-between center" fxLayoutGap="24px" fxLayoutGap.xs="16">
          <div fxLayoutAlign="start center" fxLayoutGap="12px" class="thumbnail truncate">
            <img src="../../../../assets/img/document_attach.svg" class="icon-document">
            <div class="name truncate">
              <div>{{attachment.title}}</div>
              <div class="pending truncate" *ngIf="!attachment.hash || attachment.pending">
                {{'attachment.pending'|translate}}
              </div>
            </div>
          </div>
          <div class="options" fxLayoutAlign="end center">
            <button mat-icon-button (click)="download(attachment);$event.stopPropagation();">
              <mat-icon>file_download</mat-icon>
            </button>
            <button mat-icon-button (click)="openDialog(i, attachment);$event.stopPropagation();">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="del(i, attachment);$event.stopPropagation();">
              <mat-icon *ngIf="!attachment.delete">delete</mat-icon>
              <mat-icon *ngIf="attachment.delete">delete_forever</mat-icon>
            </button>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>

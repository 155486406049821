<ng-container *ngIf="showCookiesInfo">
  <div class="accept-cookies-block">
    <div class="container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
      <div class="title">Esta página web usa cookies</div>
      <div class="info">
        Utilizamos cookies propias y de terceros para analizar nuestros servicios y mostrarte publicidad relacionada con
        tus preferencias en base a un perfil elaborado a partir de tus hábitos de navegación (por ejemplo, páginas
        visitadas). Puedes obtener más información y configurar tus preferencias <a href="cookies"
          class="more-info">aquí</a>
      </div>
      <div class="actions" fxLayoutAlign="center stretch" fxLayoutGap="5px">
        <button class="all-button" (click)="all()">Permitir todas</button>
        <button class="necessary-button" (click)="necessary()">Sólo necesarias</button>
        <button class="selected-button" (click)="selected()">Permitir la selección</button>
        <button class="none-button" (click)="none()">Rechazar todas</button>
      </div>
      <div class="options" fxLayoutAlign="center center" fxLayoutGap="10px">
        <label><input type="checkbox" name="necessary_cookies" checked="checked"
            disabled="true"><span>Necesarias</span></label>
        <label><input type="checkbox" name="functional_cookies" #functionalCheck
            [checked]="functionalCookies"><span>Funcionales</span></label>
        <label><input type="checkbox" name="stats_cookies" #statsCheck
            [checked]="statsCookies"><span>Estadísticas</span></label>
        <button (click)="toggleDetails()" class="details-button">
          <span *ngIf="!detailShowed">mostrar</span>
          <span *ngIf="detailShowed">ocultar</span>
          detalles
        </button>
      </div>
      <div class="details" #detailsTable>
        <table width="100%" border="1">
          <tr>
            <td class="type">Necesarias</td>
            <td>Cosital</td>
            <td>__CositalCookiesConsent</td>
            <td> Recoge su configuración de la política de cookies para este sitio web. </td>
          </tr>
          <tr>
            <td class="type">Estadísticas</td>
            <td>Google</td>
            <td> _ga, _ga_{{GOOGLE_ANALYTICS4_ID}}, _gat, _gid </td>
            <td>Recoger información sobre la navegación de los usuarios por el sitio con el fin de conocer el origen de
              las
              visitas y otros datos similares a nivel estadístico. Google Analytics utiliza también estas cookies para
              mostrarle
              publicidad relacionada con su perfil de navegación. </td>
          </tr>
          <tr>
            <td rowspan="5" class="type">Funcionales</td>
            <td>Cosital</td>
            <td>__Cosital_popup_XXX</td>
            <td>Matiene las opciones del usuario con respecto a la visibilidad de los anuncios emergentes mostrados por
              Cosital</td>
          </tr>
          <tr>
            <td>Youtube</td>
            <td>VISITOR_INFO1_LIVE, YSC </td>
            <td>Cookies de Youtube utilizadas en la reproducción de vídeos a través de la web. </td>
          </tr>
          <tr>
            <td>Vimeo</td>
            <td>_ga_126VYLCXDY, __ssid, sd_client_id, _gcl_au, continuous_play_v3, _abexps, _ga, _fbp, player, vuid</td>
            <td>Cookies de Vimeo utilizadas en la reproducción de vídeos a través de la web. </td>
          </tr>
          <tr>
            <td>shelf.bhybrid.com</td>
            <td>vvt</td>
            <td>Esta cookie de sesión se instala únicamente al entrar en las Publicaciones como usuario suscriptor.</td>
          </tr>
          <tr>
            <td>AddThis</td>
            <td>ouid, na_id, na_tc, loc, uvc, uid, ssc, ssh, sshs, notice_gdpr_prefs, notice_preferences</td>
            <td>Cookies de AddThis utilizadas en el plugin para permitir a los visitantes compartir contenido.</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</ng-container>
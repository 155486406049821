<form [formGroup]="form">
  <div class="header">
    <h2 mat-dialog-title>{{'collegiateUpdate.title' | translate}}</h2>
  </div>

  <mat-dialog-content>

    <div class="text-content" [innerHTML]="'collegiateUpdate.infoText'|translate"></div><br>

    <div>
      <mat-form-field class="is-collegiate-field">
        <mat-label>{{ 'collegiateUpdate.isCollegiate' | translate }}</mat-label>
        <mat-select placeholder="{{ 'collegiateUpdate.isCollegiate' | translate }}"
          formControlName="isCollegiateUpdate">
          <mat-option [value]="null">Sin informar</mat-option>
          <mat-option [value]="1">Sí</mat-option>
          <mat-option [value]="0">No</mat-option>
        </mat-select>
        <mat-error>{{'general.required' |translate}}</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="this.form.controls['isCollegiateUpdate'].value === 1">
        <mat-label>{{ 'collegiateUpdate.school' | translate }}</mat-label>
        <mat-select placeholder="{{ 'collegiateUpdate.school' | translate }}" formControlName="schoolCollegiate"
          required>
          <mat-option [value]="province.provinceID" *ngFor="let province of provinces">
            {{province.name}}</mat-option>
        </mat-select>
        <mat-error>{{'general.required' |translate}}</mat-error>
      </mat-form-field>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions>
    <web-loading-button [disabled]="!form.valid" [loading]="false" [msg]="'collegiateUpdate.send' | translate"
      [loadingMsg]="'collegiateUpdate.send' | translate" (click)="submit();">
    </web-loading-button>
  </mat-dialog-actions>

</form>
<web-section-title *ngIf="showSectionTitle" [title]="sectionTitle"></web-section-title>

<div class="category-text text-content" *ngIf="category && category.text && category.text.length>0"
  [innerHTML]="category.text | safeHtml"></div>

<div class="info-message"
  *ngIf="!this.authService.user && category && category.locationObservatoryID && category.publicPrivate">
  <div [innerHTML]="'general.publicPrivateText'|translate"></div><br />
  <button mat-flat-button color="primary"
    (click)="publicPrivateLogin()">{{'general.publicPrivateLink'|translate}}</button>
</div>

<div class="divider" *ngIf="category && category.text && category.text.length>0"></div>

<!-- CAROUSEL Template -->
<div class="disposition-carousel" *ngIf="disposition === DISPOSITION_CAROUSEL">
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let item of items$ | async; let i = index">
      <ng-template carouselSlide>
        <div class="item" fxLayout.xs="column" fxLayoutAlign="start start">
          <a
            [routerLink]="item.elementTypeID && item.elementTypeID === COVER_ELEMENT_TYPE_CONTENTS ? (item.url + '/' + item[itemKeyName] + '/' + (item.title | slug)) : item.url">
            <div *ngIf="showImage" class="image-container">
              <div fxHide="false" fxHide.xs class="image" [style.backgroundImage]="item.backgroundImg">
              </div>
              <img *ngIf="item.image" fxShow="false" fxShow.xs class="image" [src]="item.image" [alt]="item.title">
            </div>
            <div class="info">
              <div class="pre-header" fxLayoutAlign="start center">
                <div class="date" *ngIf="item.date">{{item.date | date:'mediumDate'}}</div>
                <div class="observatory" *ngIf="item.observatoryName">{{item.observatoryName}}</div>
                <div class="category" *ngIf="showCategory">{{item.categoryName}}</div>
              </div>
              <div class="title">{{item.title}}</div>
              <div class="text" [innerHTML]="item.text|truncate:200"></div>
            </div>
          </a>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>

<ng-container *ngIf="disposition !== DISPOSITION_CAROUSEL">
  <div [class.disposition-list]="disposition === DISPOSITION_LIST"
    [class.disposition-grid]="disposition === DISPOSITION_GRID"
    [class.disposition-jumbotron]="disposition === DISPOSITION_JUMBOTRON">
    <ng-container *ngIf="filter">
      <div class="item"
        *ngFor="let item of items$ | async | paginate: { id: 'registers', itemsPerPage: quantity, currentPage: (page + 1), totalItems: total }; let i = index">
        <ng-container *ngIf="disposition === DISPOSITION_LIST">
          <ng-container *ngTemplateOutlet="listItemTemplate; context: {item: item, index: i}"></ng-container>
        </ng-container>

        <ng-container *ngIf="disposition === DISPOSITION_GRID">
          <ng-container *ngTemplateOutlet="gridItemTemplate; context: {item: item, index: i}"></ng-container>
        </ng-container>

        <ng-container *ngIf="disposition === DISPOSITION_JUMBOTRON">
          <ng-container *ngTemplateOutlet="jumbotronItemTemplate; context: {item: item, index: i}">
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="!filter">
      <div *ngFor="let item of items$ | async; let i = index" class="item">
        <ng-container *ngIf="disposition === DISPOSITION_LIST">
          <ng-container *ngTemplateOutlet="listItemTemplate; context: {item: item, index: i}"></ng-container>
        </ng-container>

        <ng-container *ngIf="disposition === DISPOSITION_GRID">
          <ng-container *ngTemplateOutlet="gridItemTemplate; context: {item: item, index: i}"></ng-container>
        </ng-container>

        <ng-container *ngIf="disposition === DISPOSITION_JUMBOTRON">
          <ng-container *ngTemplateOutlet="jumbotronItemTemplate; context: {item: item, index: i}">
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <!-- Paginación -->
  <div class="pagination" *ngIf="paginate">
    <pagination-controls class="web-pagination" (pageChange)="loadPage($event)"
      [previousLabel]="'general.previousPage' | translate" [nextLabel]="'general.nextPage' | translate"
      [responsive]="true" [autoHide]="true" id="registers"></pagination-controls>
  </div>

  <div *ngIf="(items$ | async)?.length==0">{{'general.noContent' | translate}}</div>

  <!-- LIST Template -->
  <ng-template #listItemTemplate let-item="item">
    <div fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutGap.xs="12px">
      <div *ngIf="showImage && item.image">
        <div fxHide="false" fxHide.xs class="image" [style.backgroundImage]="item.backgroundImg"></div>
        <img *ngIf="item.image" fxShow="false" fxShow.xs class="image" [src]="item.image" [alt]="item.title">
      </div>
      <div class="info">
        <div class="title">{{item.title}}</div>
        <div class="pre-header" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start start">
          <div class="date" *ngIf="item.date">{{item.date | date:'mediumDate'}}</div>
          <div class="observatory" *ngIf="item.observatoryName">{{item.observatoryName}}</div>
          <div class="category" *ngIf="showCategory">{{item.categoryName}}</div>
        </div>
        <div class="text text-content" [innerHTML]="item.text|safeHtml"></div>
        <a mat-flat-button color="accent" class="more-info"
          [routerLink]="item.elementTypeID && item.elementTypeID === COVER_ELEMENT_TYPE_CONTENTS ? (item.url + '/' + item[itemKeyName] + '/' + (item.title | slug)) : item.url">{{'general.readMore'|translate}}</a>
      </div>
    </div>
  </ng-template>

  <!-- GRID Template -->
  <ng-template #gridItemTemplate let-item="item">
    <div class="item-content" fxLayout="column" fxLayoutAlign="start start">
      <div class="width-100">
        <div *ngIf="showImage && item.image">
          <div class="image" [style.backgroundImage]="item.backgroundImg"></div>
          <img *ngIf="item.image" class="image-xs" [src]="item.image" [alt]="item.title">
        </div>
        <div class="info">
          <div class="pre-header">
            <div class="date" *ngIf="item.date">{{item.date | date:'mediumDate'}}</div>
            <div class="observatory" *ngIf="item.observatoryName">{{item.observatoryName}}</div>
            <div class="category" *ngIf="showCategory">{{item.categoryName}}</div>
          </div>
          <div class="title">{{item.title}}</div>
          <div class="text text-content" [innerHTML]="item.text|truncate:item.textTruncate"></div>
        </div>
      </div>
      <a mat-flat-button color="accent" class="more-info"
        [routerLink]="item[itemKeyName] + '/' + (item.title | slug)">{{'general.readMore'|translate}}</a>
    </div>
  </ng-template>

  <!-- JUMBOTRON Template -->
  <ng-template #jumbotronItemTemplate let-item="item">
    <div fxLayout.xs="column" fxLayoutAlign="start start">
      <div *ngIf="showImage" class="image-container">
        <div fxHide="false" fxHide.xs class="image" [style.backgroundImage]="item.backgroundImg"></div>
        <img *ngIf="item.image" fxShow="false" fxShow.xs class="image" [src]="item.image" [alt]="item.title">
      </div>
      <div class="info">
        <div class="pre-header" fxLayoutAlign="start center" fxLayoutGap="15px">
          <div fxLayoutAlign="start center" fxLayoutGap="5px">
            <mat-icon class="date-icon">event</mat-icon>
            <div class="date" *ngIf="item.date">{{item.date | date:'mediumDate'}}</div>
          </div>
          <div class="category" *ngIf="showCategory" [style.backgroundColor]="item.categoryColor"
            [style.color]="invertColor(item.categoryColor, true)">{{item.categoryName}}</div>
          <div class="private" *ngIf="item.private">
            <mat-icon class="private-icon" [style.color]="item.categoryColor">lock_outline</mat-icon>
          </div>
        </div>
        <div class="title">{{item.title}}</div>
        <div class="text" [innerHTML]="item.text|truncate:200"></div>
      </div>
    </div>
  </ng-template>

</ng-container>
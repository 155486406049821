import { IResponse } from '@web/base/shared/models/response.model';
import { BehaviorSubject } from 'rxjs';
import { CoreService } from './../core.service';
import { BaseComponent } from '@web/base/shared/components/base/base.component';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';

@Component({
  selector: 'web-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent extends BaseComponent implements OnInit {

  private events: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  public events$ = this.events.asObservable();
  private oldMonth: string;

  public loadingEvents: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public loadingEvents$ = this.loadingEvents.asObservable();

  constructor(translate: TranslateService, private coreService: CoreService) {
    super(translate);
  }

  ngOnInit() {
  }

  dateSelected(value: Moment) {
    if (value && (value.format('MM') !== this.oldMonth)) {
      this.oldMonth = value.format('MM');
      this.loadCalendar(value);
    }
  }

  loadCalendar(value: Moment) {
    this.events.next([]);
    setTimeout(() => this.loadingEvents.next(true));
    this.coreService.loadEventsByMonthAndYear(+value.format('MM'), +value.format('YYYY')).subscribe((res: IResponse) => {
      this.events.next(res.result);
      setTimeout(() => this.loadingEvents.next(false));
    }, err => {
      console.log('err', err);
      setTimeout(() => this.loadingEvents.next(false));
    });
  }
}

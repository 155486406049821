<div class="header">
  <h2 mat-dialog-title>{{'core.signin.rememberPassword' | translate}}</h2>
  <h3> {{'core.signin.rememberPasswordText' | translate}}</h3>
</div>

<form [formGroup]="form" (submit)="rememberPass();">

  <mat-dialog-content>
    <mat-form-field>
      <mat-label>{{'core.signin.username' | translate}}</mat-label>
      <span matPrefix>
        <mat-icon>perm_identity</mat-icon>
      </span>
      <input matInput placeholder="{{'core.signin.username' | translate}}" formControlName="username">
      <mat-error>{{'general.required' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'core.signin.email' | translate}}</mat-label>
      <span matPrefix>
        <mat-icon>mail</mat-icon>
      </span>
      <input matInput placeholder="{{'core.signin.email' | translate}}" formControlName="email">
      <mat-error>{{'general.required' | translate}}</mat-error>
    </mat-form-field>
    <h3>Contacto: 91 521 18 25</h3>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="accent">{{'general.send' | translate}}</button>
  </mat-dialog-actions>
</form>
<form [formGroup]="form">
  <mat-form-field floatPlaceholder="always" [class.not-spanish]="form.controls['iban'].value.substr(0,2) !== 'ES'">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput #iban class="iban" [required]="required" [placeholder]="placeholder" formControlName="iban"
      pattern="[A-Z0-9]*" [attr.size]="ibanLength" [attr.maxlength]="ibanLength"
      (paste)="onPaste($event); $event.preventDefault()">
  </mat-form-field>
  <ng-container *ngIf="form.controls['iban'].value.substr(0, 2) === 'ES'">
    <!-- <input class="iban" formControlName="iban" size="4" maxlength="4"
      (paste)="onPaste($event); $event.preventDefault()"> -->
    <span>-</span>
    <mat-form-field>
      <input matInput #field1 class="field1" formControlName="field1" size="4" maxlength="4"
        (paste)="onPaste($event); $event.preventDefault()">
    </mat-form-field>
    <span>-</span>
    <mat-form-field>
      <input matInput #field2 class="field2" formControlName="field2" size="4" maxlength="4"
        [type]="secrect ? 'password' : 'text'" (paste)="onPaste($event); $event.preventDefault()">
    </mat-form-field>
    <span>-</span>
    <mat-form-field>
      <input matInput #field3 class="field3" formControlName="field3" size="4" maxlength="4"
        [type]="secrect ? 'password' : 'text'" (paste)="onPaste($event); $event.preventDefault()">
    </mat-form-field>
    <span>-</span>
    <mat-form-field>
      <input matInput #field4 class="field4" formControlName="field4" size="4" maxlength="4"
        [type]="secrect ? 'password' : 'text'" (paste)="onPaste($event); $event.preventDefault()">
    </mat-form-field>
    <span>-</span>
    <mat-form-field>
      <input matInput #field5 class="field5" formControlName="field5" size="4" maxlength="4"
        (paste)="onPaste($event); $event.preventDefault()">
    </mat-form-field>
  </ng-container>
  <div class="iban-error" *ngIf="form.controls['iban'].value && form.hasError('incorrectIBAN')">IBAN incorrecto</div>
</form>
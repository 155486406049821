import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[vfMoveNextOnComplete]'
})
export class MoveNextOnCompleteDirective {

  // Elementos que no tenemos en cuenta.
  public notAllowedTags = { span: false };

  constructor(public el: ElementRef) { }


  /**
   * Para escuchar el evento input que nos permitirá saber cuando ha cambiado el contenido del objeto.
   */
  @HostListener('input') onChangesD() {
    this.changeToNextElement();
  }


  /**
   * Para controlar si hemos llegado al tamaño máx. del objeto y de ser así pasar el foco al siguiente.
   */
  public changeToNextElement() {
    const element = this.el.nativeElement;

    if (element.value.length === element.maxLength) {
      const nextEl = this.findNextTabStop(this.el);
      nextEl.focus();
    }

  }


  /**
   * Para saber cual es el siguiente objeto.
   * @param elementOrg El elemento desde el que partimos al poder ser revursivo.
   */
  findNextTabStop(elementOrg: ElementRef) {

    let nextElement = elementOrg.nativeElement;

    if (!nextElement) {
      nextElement = elementOrg;
    }

    nextElement = nextElement.nextElementSibling;

    if (nextElement.localName in this.notAllowedTags) {
      return this.findNextTabStop(nextElement);
    } else {
      return nextElement;
    }

  }

}

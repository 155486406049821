<div class="attachments-gallery">
  <div class="items">
    <a href="{{attachment.url}}" target="_blank" [title]="attachment.title" *ngFor="let attachment of attachments"
      class="item">
      <div fxLayoutAlign="start center" fxLayoutGap="12px" class="file">
        <mat-icon class="attachment">attachment</mat-icon>
        <div class="title truncate">{{ attachment.title }}</div>
      </div>
      <mat-icon class="download">file_download</mat-icon>
    </a>
  </div>
</div>
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { IAttachment } from '../attachment.model';
import { Observable } from 'rxjs';
import { EditAttachmentComponent } from './edit-attachment/edit-attachment.component';

@Injectable()
export class AttachmentsHandleDialogService {

  constructor(public dialog: MatDialog) { }

  openDialogAttachment(
    index: number,
    formData: FormData,
    // attachment?: IAttachment,
    attachments?: Array<IAttachment>,
    canActive = true,
    acceptedFiles = ''
  ): Observable<boolean | { attachment: IAttachment, data: FormData }> {
    let dialogRef: MatDialogRef<EditAttachmentComponent>;
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.width = '85%';
    config.maxWidth = 600;
    dialogRef = this.dialog.open(EditAttachmentComponent, config);

    dialogRef.componentInstance.index = index;
    dialogRef.componentInstance.formData = formData;
    // dialogRef.componentInstance.attachment = attachment;
    dialogRef.componentInstance.attachments = attachments;
    dialogRef.componentInstance.canActive = canActive;
    dialogRef.componentInstance.acceptedFiles = acceptedFiles;

    return dialogRef.afterClosed();
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@web/base/shared/components/base/base.component';

@Component({
  selector: 'web-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit {

  constructor(
    translate: TranslateService,
    dialog: MatDialog,
  ) {
    super(translate, null, dialog);
  }

  ngOnInit() {
    this.getTranslations(['core']);
  }

  openDataProtection() {
    this.openSimpleDialog(this.translations['core']['dataProtection'], this.translations['core']['dataProtectionContent']);
  }

  openCookiesPolicy() {
    this.openSimpleDialog(this.translations['core']['cookiesPolicy'], this.translations['core']['cookiesPolicyContent']);
  }

  openLegalWarning() {
    this.openSimpleDialog(this.translations['core']['legalWarning'], this.translations['core']['legalWarningContent']);
  }

}

<div class="images-gallery" *ngIf="images.length" #imageGallery>
  <h4>{{ title }}</h4>

  <mat-grid-list cols="4" rowHeight="1:1" gutterSize="8px" #grid>
    <mat-grid-tile *ngFor="let image of images; let i = index">
      <div class="gallery-spinner-wrapper" *ngIf="!image.loaded || image.loaded === undefined">
        <mat-spinner color="primary" [strokeWidth]="3" [diameter]="36"></mat-spinner>
      </div>
      <img class="image" [style.opacity]="image.loaded ? 1 : 0" [src]="image.url" [alt]="image.title"
        [@fade]="(image.loaded===false || image.loaded===undefined) ? 'fadeOut' : 'fadeIn'"
        (load)="onLoadImage(i, $event)" (click)="openImage(image, null, i)">
    </mat-grid-tile>
  </mat-grid-list>

</div>
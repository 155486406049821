<div class="private-access">
  <div fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxLayoutGap.xs="12px">
    <mat-icon class="lock-icon">lock_outline</mat-icon>
    <div>
      <h2>{{'signin.title' | translate}}</h2>
      <!-- <h3>{{'signin.introText01' | translate}}</h3> -->
      <div class="text-content" [innerHTML]="'signin.introText01' | translate"></div>

      <div class="text-content" [innerHTML]="textLink" (click)="link();"></div>
      <!-- <div *ngIf="typeLink === URLS_TYPES_PRODUCTS || typeLink === URLS_TYPES_COURSES"
        [routerLink]="('/usuarios/registro?returnUrl=' + returnURL)" class="text-content" [innerHTML]="textLink"></div>
      <div *ngIf="typeLink === URLS_TYPES_CONTACTS" [routerLink]="'/informacion/suscripciones'" class="text-content"
        [innerHTML]="textLink"></div> -->

      <div class="text-content" [innerHTML]="'general.phoneContact' | translate"></div>

      <form [formGroup]="form" (submit)="login();">

        <div class="login-box material-shadow">

          <mat-form-field>
            <span matPrefix>
              <mat-icon>person_outline</mat-icon>
            </span>
            <mat-label>{{'core.signin.username' | translate}}</mat-label>
            <input matInput placeholder="{{'core.signin.username' | translate}}" formControlName="username" required>
            <mat-error>{{'general.required' | translate}}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <span matPrefix>
              <mat-icon>lock_outline</mat-icon>
            </span>
            <mat-label>{{'core.signin.password' | translate}}</mat-label>
            <input #password type="password" matInput placeholder="{{'core.signin.password' | translate}}"
              formControlName="password" id="password" required>
            <button matSuffix mat-icon-button class="btn-show-password btn btn-primary" type="button"
              aria-label="Mostrar contraseña" (mousedown)="showPass()" (mouseup)="hidePass()">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
          </mat-form-field>

          <div class="error-message" *ngIf="authService.errorLogin">
            {{ authService.errorLogin }}
          </div>
          <!--
          <div fxLayoutAlign="space-between center" fxLayoutGap="24px">
            <a mat-button color="primary"
              (click)="openRememberPasswordDialog()">{{'signin.rememberPassword' | translate}}</a>
            <button mat-flat-button color="primary" (click)="login()">{{'general.enter' | translate}}</button>
          </div> -->
          <div fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="start start"
            fxLayoutGap="15px" fxLayoutGap.xs="8px">
            <button mat-flat-button color="primary" (click)="login()">{{'general.enter' | translate}}</button>
            <a mat-button color="accent" class="remember-pass-btn"
              (click)="openRememberPasswordDialog();">{{'signin.rememberPassword' | translate}}</a>
          </div>

        </div>
      </form>
    </div>

  </div>

</div>
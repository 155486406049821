<div class="shared-actions shared-actions-small" fxLayoutAlign="start center" fxLayoutGap="5px">
  <button mat-mini-fab class="download" (click)="download(content.contentID)" title="Descargar">
    <mat-icon>save_alt</mat-icon>
  </button>
  <button mat-mini-fab class="print" (click)="sendToPrinter(content)" title="Imprimir">
    <mat-icon>print</mat-icon>
  </button>
  <button mat-mini-fab color="accent" (click)="toggleSharedOptions()" title="Compartir">
    <mat-icon>shared</mat-icon>
  </button>
  <div class="shared-options" [@sharedAnimationState]="sharedOptionsState">
    <div fxLayoutAlign="start center" fxLayoutGap="5px">
      <button mat-mini-fab class="shared-option-button" (click)="shared(SHARED_FACEBOOK, content)">
        <mat-icon svgIcon="ico-facebook"></mat-icon>
      </button>
      <button mat-mini-fab class="shared-option-button whatsapp-button" (click)="shared(SHARED_WHATSAPP, content)">
        <mat-icon svgIcon="ico-whatsapp"></mat-icon>
      </button>
      <button mat-mini-fab class="shared-option-button" (click)="shared(SHARED_TWITTER, content)">
        <mat-icon svgIcon="ico-twitter"></mat-icon>
      </button>
      <button mat-mini-fab class="shared-option-button" (click)="shared(SHARED_LINKED_IN, content)">
        <mat-icon svgIcon="ico-linked-in"></mat-icon>
      </button>
      <button mat-mini-fab class="shared-option-button" (click)="shared(SHARED_EMAIL, content)">
        <mat-icon svgIcon="ico-email"></mat-icon>
      </button>
    </div>
  </div>
</div>
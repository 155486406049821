<div class="menu-header">
  <img src="./../../../assets/img/logo.svg" class="logo" alt="logo">
</div>

<!-- Buscador -->
<div [formGroup]="form" class="search-bar" fxLayoutAlign="space-between center">
  <mat-form-field class="search-input" floatLabel="never">
    <input type="text" matInput formControlName="searchText" placeholder="{{ 'general.search' | translate }}...">
    <button mat-icon-button matSuffix (click)="searchAdvanced()"
      [disabled]="form.controls['searchText'].value && form.controls['searchText'].value.length<MIN_LENGTH_TO_SEARCH">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>
</div>

<ul class="menu-mobile" #mainMenu>
  <ng-container *ngFor="let item of menuMobile; let i=index;">
    <ng-container *ngIf="!item.hidden">
      <li *ngIf="item.children && item.children.length>0; else noChildren" class="has-children"
        [attr.status]="'inactive'">
        <div class="title" fxLayoutAlign="space-between center" fxLayoutGap="12px" (click)="toggleItem(i);">
          <div fxLayoutAlign="start center" fxLayoutGap="8px">
            <div fxLayoutAlign="start center" fxLayoutGap="10px" class="menu-text-container">
              <mat-icon *ngIf="item.icon" class="menu-icon">{{item.icon}}</mat-icon>
              <mat-icon *ngIf="!item.icon && item.svgIcon" svgIcon="{{item.svgIcon}}" class="menu-icon"></mat-icon>
              <span *ngIf="item.translate === undefined || item.translate">{{ translations['menu'][item.title] }}</span>
              <span *ngIf="item.translate === false">{{ item.title }}</span>
            </div>
            <mat-icon *ngIf="item.private" class="private-icon">lock</mat-icon>
          </div>
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
        <menu-mobile-item [items]="item.children" level="1" (clickItem)="onClickItem($event)"
          (clickFinalItem)="closeSidenav();"></menu-mobile-item>
      </li>
    </ng-container>

    <ng-template #noChildren>
      <li *ngIf="!item.external" (click)="closeSidenav();">
        <a [routerLink]="item.url"
          [title]="item.translate === undefined || item.translate ? translations['menu'][item.title] : (item.translate === false ? item.title : '')">
          <div class="title" fxLayoutAlign="start center" fxLayoutGap="8px">
            <div fxLayoutAlign="start center" fxLayoutGap="10px" class="menu-text-container">
              <mat-icon *ngIf="item.icon" class="menu-icon">{{item.icon}}</mat-icon>
              <mat-icon *ngIf="!item.icon && item.svgIcon" svgIcon="{{item.svgIcon}}" class="menu-icon"></mat-icon>
              <span *ngIf="item.translate === undefined || item.translate">{{ translations['menu'][item.title] }}</span>
              <span *ngIf="item.translate === false">{{ item.title }}</span>
            </div>
            <mat-icon *ngIf="item.private" class="private-icon">lock</mat-icon>
          </div>
        </a>
      </li>

      <li *ngIf="item.external" (click)="closeSidenav();" [class.external]="item.external">
        <a [href]="item.url" target="_blank" title="{{ translations['menu'][item.title] }}">
          <div class="title" fxLayoutAlign="start center" fxLayoutGap="8px">
            <div fxLayoutAlign="start center" fxLayoutGap="10px" class="menu-text-container">
              <mat-icon *ngIf="item.icon" class="menu-icon">{{item.icon}}</mat-icon>
              <mat-icon *ngIf="!item.icon && item.svgIcon" svgIcon="{{item.svgIcon}}" class="menu-icon"></mat-icon>
              <span *ngIf="item.translate === undefined || item.translate">{{ translations['menu'][item.title] }}</span>
              <span *ngIf="item.translate === false">{{ item.title }}</span>
            </div>
            <mat-icon *ngIf="item.private" class="private-icon">lock</mat-icon>
          </div>
        </a>
      </li>

    </ng-template>

  </ng-container>
</ul>
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from '@web/base/shared/components/base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { SimpleDialogComponent } from '@web/base/shared/components/simple-dialog/simple-dialog.component';

@Component({
  selector: 'web-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent extends BaseComponent implements OnInit {
  @ViewChild('upload', { static: true }) upload: ElementRef;

  @Output() fileChange: EventEmitter<any> = new EventEmitter();
  @Input() acceptedFiles = '';
  @Input() maxSize;

  public file;
  public reader: FileReader;
  public document: any;

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog
  ) {
    super(translate, null, dialog);

    this.document = document;
  }

  ngOnInit() {
    this.file = this.document.getElementById('upload');
    this.reader = new FileReader();
  }

  onChange(files) {
    const file = this.upload.nativeElement.files[0];
    if (file) {

      if (this.maxSize && file.size <= this.maxSize) {
        this.reader.readAsDataURL(file);
        this.reader.onload = ((e: any) => {
          // this.onFile.emit({ file: e.target.result, data: { name: file.name } });
          this.fileChange.emit(file);
        });
      } else {
        let suffix = 'mb';
        let maxSizeInMB = this.maxSize / 1024 / 1024;

        // Si el tamaño máximo es inferior a 1 mb, lo mostramos el kb para que sea más legible.
        if (maxSizeInMB < 1) {
          maxSizeInMB = maxSizeInMB * 1024;
          suffix = 'kb';
        }

        this.openSimpleDialog('Error', `El archivo no puede superar los ${maxSizeInMB} ${suffix}`, SimpleDialogComponent.TYPE_ERROR);
      }
    }
  }

  onUpload() {
    this.upload.nativeElement.dispatchEvent(new MouseEvent('click'));
  }

}

<form [formGroup]="form">
  <div fxLayoutAlign="start center">
    <mat-form-field class="time-input-hour-field">
      <input matInput class="hour" [placeholder]="placeholder" formControlName="hour" size="2" maxlength="2"
        (keydown)="keyDown($event, 'hour')" autocomplete="false" (blur)="onBlur('hour')">
      <!-- (blur)="checkValues()" -->
    </mat-form-field>
    <span>:</span>
    <mat-form-field>
      <input matInput class="minutes" formControlName="minutes" size="2" maxlength="2"
        (keydown)="keyDown($event, 'minutes')" autocomplete="false" (blur)="onBlur('minutes')">
      <!-- (blur)="checkValues()" -->
    </mat-form-field>
  </div>
</form>
<ul class="submenu" #submenu>
  <ng-container *ngFor="let child of items">
    <ng-container *ngIf="!child.hidden">
      <li *ngIf="child.children && child.children.length>0; else noChildren" [class.has-image]="child.image">
        <div fxLayoutAlign="space-between center">
          <div [class.private]="child.private" fxLayoutAlign="start center" fxLayoutGap="10px">
            <img *ngIf="child.image" src="../../../../../assets/img/menu/{{child.image}}" class="image-item">
            <span *ngIf="child.translate === undefined || child.translate">{{ translations['menu'][child.title]
              }}</span>
            <span *ngIf="child.translate === false">{{ child.title }}</span>
            <mat-icon *ngIf="child.private" class="private-icon">lock_outline</mat-icon>
          </div>
          <mat-icon class="menu-arrow">play_arrow</mat-icon>
        </div>
        <menu-sidenav-item [items]="child.children"></menu-sidenav-item>
      </li>
    </ng-container>

    <ng-template #noChildren>
      <li *ngIf="!child.external && (!child.children || child.children.length==0)" [class.has-image]="child.image"
        (click)="closeMenu(submenu);">
        <a [routerLink]="child.url" [title]="child.translate === undefined || child.translate ? translations['menu'][child.title] : (child.translate === false ?
                child.title : '')">
          <div [class.private]="child.private" fxLayoutAlign="start center" fxLayoutGap="10px">
            <img *ngIf="child.image" src="../../../../../assets/img/menu/{{child.image}}" class="image-item">
            <span *ngIf="child.translate === undefined || child.translate">{{ translations['menu'][child.title]
              }}</span>
            <span *ngIf="child.translate === false">{{ child.title }}</span>
            <mat-icon *ngIf="child.private" class="private-icon">lock_outline</mat-icon>
          </div>
        </a>
      </li>

      <li *ngIf="child.external && (!child.children || child.children.length==0)" [class.has-image]="child.image"
        (click)="closeMenu(submenu);">
        <a [href]="child.url" target="_blank" title="{{ translations['menu'][child.title] }}">
          <div [class.private]="child.private" fxLayoutAlign="start center" fxLayoutGap="10px">
            <img *ngIf="child.image" src="../../../../../assets/img/menu/{{child.image}}" class="image-item">
            <span *ngIf="child.translate === undefined || child.translate">{{ translations['menu'][child.title]
              }}</span>
            <span *ngIf="child.translate === false">{{ child.title }}</span>
            <mat-icon *ngIf="child.private" class="private-icon">lock_outline</mat-icon>
          </div>
        </a>
      </li>
    </ng-template>

  </ng-container>
</ul>
<ul class="submenu" #submenu>
  <ng-container *ngFor="let child of items">
    <ng-container *ngIf="!child.hidden">
      <li *ngIf="child.children && child.children.length>0; else noChildren" [class.separator]="child.separator">
        <div class="label" fxLayoutAlign="space-between center">
          <div *ngIf="child.translate === undefined || child.translate">{{ translations['menu'][child.title] }}</div>
          <div *ngIf="child.translate === false">{{ child.title }}</div>
          <mat-icon class="menu-arrow">play_arrow</mat-icon>
        </div>
        <ul class="sub-submenu">
          <menu-header-item [items]="child.children"></menu-header-item>
        </ul>
      </li>
    </ng-container>

    <ng-template #noChildren>
      <!-- <li *ngIf="!child.external && (!child.children || child.children.length==0)" routerLink="{{ child.url }}"
        (click)="closeMenu(submenu);" [class.separator]="child.separator"> -->
      <li *ngIf="!child.external && (!child.children || child.children.length==0)" (click)="closeMenu(submenu);"
        [class.separator]="child.separator">
        <a [title]="child.translate === undefined || child.translate ? translations['menu'][child.title] : (child.translate === false ? child.title : '')"
          [routerLink]="child.url">
          <div fxLayoutAlign="space-between center" fxLayoutGap="12px">
            <span *ngIf="child.translate === undefined || child.translate">{{ translations['menu'][child.title]
              }}</span>
            <span *ngIf="child.translate === false">{{ child.title }}</span>
            <mat-icon *ngIf="child.private" class="private-icon">lock</mat-icon>
          </div>
        </a>
      </li>

      <!-- <li *ngIf="child.external && (!child.children || child.children.length==0)" routerLink="{{ child.url }}"
        (click)="closeMenu(submenu);" [class.external]="child.external" [class.separator]="child.separator"> -->
      <li *ngIf="child.external && (!child.children || child.children.length==0)" (click)="closeMenu(submenu);"
        [class.external]="child.external" [class.separator]="child.separator">
        <a [routerLink]="child.url" target="_blank" title="{{ translations['menu'][child.title] }}">
          <div fxLayoutAlign="space-between center" fxLayoutGap="12px">
            <span *ngIf="child.translate === undefined || child.translate">{{ translations['menu'][child.title]
              }}</span>
            <span *ngIf="child.translate === false">{{ child.title }}</span>
            <mat-icon *ngIf="child.private" class="private-icon">lock</mat-icon>
          </div>
        </a>
      </li>

    </ng-template>

  </ng-container>
</ul>
<div fxHide="false" fxHide.xs fxLayoutAlign="space-around center" fxLayoutGap="12px" class="banners">
  <ng-container *ngFor="let banner of banners; let i=index">
    <div class="banner">
      <a [href]="banner.url" target="_blank" [title]="banner.title">
        <img [src]="banner.files[0].url" class="img-responsive">
      </a>
    </div>
  </ng-container>
</div>

<div class="banners-xs" fxShow="false" fxShow.xs>
  <owl-carousel-o [options]="bannerOptions">
    <ng-container *ngFor="let banner of banners">
      <ng-template carouselSlide>
        <div class="banner">
          <a [href]="banner.url" target="_blank" [title]="banner.title">
            <img [src]="banner.files[0].url">
          </a>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
import { IBaseListWebItem } from './../../../shared/components/base-list-web/base-list-web-item.model';
import { BaseListWebService } from './../../../shared/components/base-list-web/base-list-web.service';
import { Component, OnInit, Input } from '@angular/core';
import { BaseListWebComponent } from '@web/base/shared/components/base-list-web/base-list-web.component';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { Observable, Subject } from 'rxjs';
import { Constants } from '@web/base/shared/constants.class';

@Component({
  selector: 'web-home-list-content',
  templateUrl: './list-content.component.html',
  styleUrls: ['./../../../shared/components/base-list-web/base-list-web.component.scss'],
  providers: [BaseListWebService]
})
export class ListContentComponent extends BaseListWebComponent implements OnInit {
  @Input() content$: Observable<Array<IBaseListWebItem>>;

  public items$: Subject<Array<any>> = new Subject<Array<any>>();

  itemKeyName = 'elementID';
  showCategory = true;
  showSectionTitle = false;
  showSidenav = false;
  paginate = false;

  disposition: number = ConstantsProject.DISPOSITION_LIST;

  public COVER_ELEMENT_TYPE_CONTENTS = Constants.COVER_ELEMENT_TYPE_CONTENTS;

  getData() {
    this.subscriptions.push(
      this.content$.subscribe(content => {
        this.items$.next(
          content.map(c => {
            const image = c.files.find(f => f.name === 'image');
            c.image = image && image.url ? image.url : null;
            c.backgroundImg = c.image
              ? this.sanitizer.bypassSecurityTrustStyle('url(' + c.image + ')')
              : null;

            return c;
          })
        );
      })
    );
  }

  // Marcos - 30/03/2022 - Esto ahora se hace en el html.

  // goToDetail(item: any) {
  //   if (item.elementTypeID && item.elementTypeID === Constants.COVER_ELEMENT_TYPE_CONTENTS) {
  //     this.router.navigate([item.url].concat([item[this.itemKeyName]], this.slugPipe.transform(item.title)));
  //   } else {
  //     this.router.navigate([item.url]);
  //   }
  // }

  publicPrivateLogin() {
    this.router.navigate(['login'], { queryParams: { returnUrl: this.router.url } });
  }

}

<ng-template #noTitle>
  <div *ngIf="data.type===DIALOG_TYPE.ERROR">Error!</div>
  <div *ngIf="data.type===DIALOG_TYPE.OK">OK!</div>
  <div *ngIf="data.type===DIALOG_TYPE.INFO">Info</div>
</ng-template>

<div class="dialog" [class.type-info]="data.type===DIALOG_TYPE.INFO" [class.type-ok]="data.type===DIALOG_TYPE.OK"
  [class.type-error]="data.type===DIALOG_TYPE.ERROR" [class.type-confirm]="data.type===DIALOG_TYPE.CONFIRM"
  [class.type-loading]="data.type===DIALOG_TYPE.LOADING" [class.type-yes-no]="data.type===DIALOG_TYPE.YES_NO">

  <div class="header">
    <h2 mat-dialog-title fxLayoutAlign="start center" fxLayoutGap="12px">
      <mat-icon *ngIf="data.type===DIALOG_TYPE.ERROR">warning</mat-icon>
      <mat-icon *ngIf="data.type===DIALOG_TYPE.OK">check</mat-icon>
      <mat-icon *ngIf="data.type===DIALOG_TYPE.INFO">info_outline</mat-icon>
      <mat-icon *ngIf="data.type===DIALOG_TYPE.YES_NO">help_outline</mat-icon>
      <ng-container *ngIf="data.type!==DIALOG_TYPE.LOADING">
        <span *ngIf="data.title; else noTitle">{{data.title}}</span>
      </ng-container>
      <ng-container *ngIf="data.type===DIALOG_TYPE.LOADING">
        <div fxLayoutAlign="center center" fxLayoutGap="12px" class="width-100">
          <mat-spinner [diameter]=24 [strokeWidth]="3"></mat-spinner>
          <span class="title">{{data.title}}</span>
        </div>
      </ng-container>
    </h2>
  </div>

  <mat-dialog-content *ngIf="data.type!==DIALOG_TYPE.LOADING">
    <div [innerHTML]="data.content"></div>
  </mat-dialog-content>

  <mat-dialog-actions *ngIf="data.type!==DIALOG_TYPE.LOADING">
    <div *ngIf="data.type === DIALOG_TYPE.YES_NO" fxLayoutAlign="space-between center" fxLayoutGap="10px"
      class="width-100">
      <div>
        <button mat-raised-button class="btn-cancel" (click)="close()">{{ cancelText }}</button>
        <button mat-raised-button class="btn-cancel" (click)="close(false)">{{ rejectText }}</button>
      </div>
      <button mat-raised-button color="accent" (click)="close(true)">{{ confirmText }}</button>
    </div>
    <div *ngIf="data.type === DIALOG_TYPE.CONFIRM" fxLayoutAlign="space-between center" fxLayoutGap="10px"
      class="width-100">
      <button mat-raised-button class="btn-cancel" (click)="close()">{{ cancelText }}</button>
      <button mat-raised-button color="accent" (click)="close(true)">{{ confirmText }}</button>
    </div>
    <div *ngIf="data.type !== DIALOG_TYPE.CONFIRM && data.type !== DIALOG_TYPE.YES_NO" fxLayoutAlign="end center"
      class="width-100">
      <button mat-raised-button color="accent" (click)="close()" cdkTrapFocus tabIndex="-1" data-cy="ok">{{ okText
        }}</button>
    </div>
  </mat-dialog-actions>

</div>

<div id="menu-header" [ngClass]="{'fixed': fixed}">
  <ul>
    <ng-container *ngFor="let item of menuHeader;">
      <ng-container *ngIf="!item.hidden">

        <!-- With children -->
        <li *ngIf="item.children && item.children.length>0; else noChildren">
          <div class="first-level" fxLayoutAlign="center center">
            <div fxLayoutAlign="start center" fxLayoutGap="10px" class="menu-text-container">
              <mat-icon *ngIf="item.icon" class="menu-icon">{{item.icon}}</mat-icon>
              <mat-icon *ngIf="!item.icon && item.svgIcon" svgIcon="{{item.svgIcon}}" class="menu-icon"></mat-icon>
              <span *ngIf="item.translate === undefined || item.translate">{{ translations['menu'][item.title] }}</span>
              <span *ngIf="item.translate === false">{{ item.title }}</span>
            </div>
            <mat-icon class="menu-down-arrow">arrow_drop_down</mat-icon>
          </div>
          <menu-header-item [items]="item.children"></menu-header-item>
        </li>
      </ng-container>

      <!-- No children -->
      <ng-template #noChildren>
        <!-- <li *ngIf="!item.external" routerLink="{{ item.url }}"> -->
        <li *ngIf="!item.external">
          <a [title]="item.translate === undefined || item.translate ? translations['menu'][item.title] : (item.translate === false ? item.title : '')"
            [routerLink]="item.url">
            <div class="first-level" fxLayoutAlign="center center" fxLayoutGap="10px">
              <div fxLayoutAlign="start center" fxLayoutGap="10px" class="menu-text-container">
                <mat-icon *ngIf="item.icon" class="menu-icon">{{item.icon}}</mat-icon>
                <mat-icon *ngIf="!item.icon && item.svgIcon" svgIcon="{{item.svgIcon}}" class="menu-icon"></mat-icon>
                <span *ngIf="item.translate === undefined || item.translate">{{ translations['menu'][item.title]
                  }}</span>
                <span *ngIf="item.translate === false">{{ item.title }}</span>
              </div>
              <mat-icon *ngIf="item.private" class="private-icon">lock</mat-icon>
            </div>
          </a>
        </li>

        <!-- Link externo -->
        <li *ngIf="item.external" [class.external]="item.external">
          <a [href]="item.url" target="_blank" title="{{ translations['menu'][item.title] }}">
            <div class="first-level" fxLayoutAlign="center center" fxLayoutGap="10px">
              <div fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-icon *ngIf="item.icon" class="menu-icon">{{item.icon}}</mat-icon>
                <mat-icon *ngIf="!item.icon && item.svgIcon" svgIcon="{{item.svgIcon}}" class="menu-icon"></mat-icon>
                <span *ngIf="item.translate === undefined || item.translate">{{ translations['menu'][item.title]
                  }}</span>
                <span *ngIf="item.translate === false">{{ item.title }}</span>
              </div>
              <mat-icon *ngIf="item.private" class="private-icon">lock</mat-icon>
            </div>
          </a>
        </li>
      </ng-template>

    </ng-container>
  </ul>
</div>
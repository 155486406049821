<div [class]="classList">

  <!-- Galeria adjuntos -->
  <div *ngIf="files && files.length" class="attachments-module">
    <web-attachments-module [attachments]="files" order="asc" [showTitle]="showTitles"
      title="{{ 'general.attachmentsGallery' | translate }}">
    </web-attachments-module>
  </div>


  <!-- Galeria imagenes -->
  <div *ngIf="images && images.length" class="images-module">
    <web-images-module [images]="images" order="asc" [showTitle]="showTitles"
      title="{{ 'general.imagesGallery' | translate }}">
    </web-images-module>
  </div>


  <!-- Galeria vídeos -->
  <div *ngIf="videos && videos.length" class="videos-module">
    <web-videos-module [videos]="videos" order="asc" [showTitle]="showTitles"
      title="{{ 'general.videosGallery' | translate }}">
    </web-videos-module>
  </div>

</div>
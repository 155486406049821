<div class="section-title">
  <div class="section-title-layout">
    <div class="section-title-layout__container">
      <h2>{{title}}</h2>
      <h3>{{subtitle}}</h3>
    </div>
    <div class="buttons">
      <mat-icon *ngIf="private" class="locked">locked</mat-icon>
      <button *ngIf="back" mat-icon-button color="primary" class="back" attr.aria-label="{{'general.back'|translate}}"
        (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>
</div>
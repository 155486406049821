<div class="banners">

  <div *ngFor="let banner of banners" class="banner">
    <a *ngIf="banner.url!=='https://www.tirantonline.com/tol/' && banner.url!=='https://biblioteca.nubedelectura.com/cloudLibrary/'"
      [href]="banner.url" target="_blank" [title]="banner.title">
      <img [src]="banner.files[0].url" class="img-responsive">
    </a>
    <button
      *ngIf="banner.url==='https://www.tirantonline.com/tol/' || banner.url==='https://biblioteca.nubedelectura.com/cloudLibrary/'"
      (click)="handleToBanner(banner.url)" [title]="banner.title">
      <img [src]="banner.files[0].url" class="img-responsive">
    </button>
  </div>

</div>
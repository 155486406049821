import { MenuItem } from './menu-item.model';
import { Injectable } from '@angular/core';
import { ConstantsProject } from '@web/project/shared/constants.class';

@Injectable()
export class MenuService {
  private menuMobile: MenuItem[];

  constructor() { }

  createMenu() {
    // Para el menú móvil añadimos tb un link a la home.
    var MENU_HOME: Array<MenuItem> = [{ title: 'home', url: 'home' }];
    this.menuMobile = MENU_HOME.concat(ConstantsProject.MENU_SIDENAV, ConstantsProject.MENU_HEADER);
  }

  getMenuSidenav() {
    return ConstantsProject.MENU_SIDENAV;
  }
  getMenuHeader() {
    return ConstantsProject.MENU_HEADER;
  }
  getMenuMobile() {
    return this.menuMobile;
  }
}

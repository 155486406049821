<div class="header">
  <h2 mat-dialog-title>{{'core.signin.login' | translate}}</h2>
  <h3> {{'core.signin.loginText' | translate}}</h3>
</div>

<form [formGroup]="form">

  <mat-dialog-content>

    <mat-form-field>
      <span matPrefix>
        <mat-icon>person_outline</mat-icon>
      </span>
      <mat-label>{{'core.signin.username' | translate}}</mat-label>
      <input matInput placeholder="{{'core.signin.username' | translate}}" formControlName="username" required>
      <mat-error>{{'general.required' | translate}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <span matPrefix>
        <mat-icon>lock_outline</mat-icon>
      </span>
      <mat-label>{{'core.signin.password' | translate}}</mat-label>
      <input #password type="password" matInput placeholder="{{'core.signin.password' | translate}}"
        formControlName="password" id="password" required>
      <button matSuffix mat-icon-button class="btn-show-password btn btn-primary" type="button"
        aria-label="Mostrar contraseña" (mousedown)="showPass()" (mouseup)="hidePass()">
        <mat-icon>remove_red_eye</mat-icon>
      </button>
    </mat-form-field>

    <div class="error-message" *ngIf="authService.errorLogin">
      {{ authService.errorLogin }}
    </div>

  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="buttons-line-1">
      <button mat-flat-button color="primary" (click)="login()">{{'general.enter' | translate}}</button>
      <a mat-button class="remember-pass-button"
        (click)="openRememberPasswordDialog()">{{'core.signin.rememberPassword'|translate}}</a>
    </div>
    <button mat-stroked-button class="register-btn"
      (click)="linkRegister(); $event.preventDefault()">{{'core.signin.register' |
      translate}}</button>
  </mat-dialog-actions>

</form>
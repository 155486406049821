import { OverlayRef } from '@angular/cdk/overlay';
import { Subject, Observable } from 'rxjs';
import { ImageGalleryComponent } from './image-gallery.component';

export class ImageGalleryOverlayRef {

  private _beforeClose = new Subject<void>();
  private _afterClosed = new Subject<void>();
  public componentInstance: ImageGalleryComponent;

  constructor(private overlayRef: OverlayRef) { }

  afterClosed(): Observable<void> {
    return this._afterClosed.asObservable();
  }

  beforeClose(): Observable<void> {
    return this._beforeClose.asObservable();
  }

  close(): void {
    this.componentInstance.animationStateChanged.subscribe(
      (event) => {
        if (event.phaseName === 'start') {
          this._beforeClose.next();
          this._beforeClose.complete();
          this.overlayRef.detachBackdrop();
        }
        if (event.phaseName === 'done' && event.toState === 'leave') {
          this.overlayRef.dispose();
          this._afterClosed.next();
          this._afterClosed.complete();
        }
      }
    );

    this.componentInstance.startExitAnimation();
  }
}

import { BaseComponent } from '@web/base/shared/components/base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { Component, Output, EventEmitter, ViewChild, Renderer2, AfterViewInit, Input, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { ICalendarItem } from './calendar-item.model';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MatCalendar } from '@angular/material/datepicker';
// import { CapitalizePipe } from '../../pipes/capitalizePipe';

@Component({
  selector: 'web-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})

export class CalendarComponent extends BaseComponent implements OnInit, AfterViewInit {

  // Input que contiene los eventos a representar en el calendatio, con la estructura de ICalendarItem
  @Input() events$: Observable<ICalendarItem[]>;

  // Input que indica si se están cargando los eventos
  @Input() loadingEvents$: Observable<boolean>;
  public loadingEvents: boolean;

  // Output que emite cuando se selecciona una fecha del calendario.
  @Output() dateSelected: EventEmitter<moment.Moment> = new EventEmitter();

  // Fecha actualmente seleccionada.
  selectedDate = moment();

  // Referencia al calendario
  @ViewChild('calendar', { static: false }) public calendar: MatCalendar<moment.Moment>;

  // Eventos transformados desde los eventos recibidos por el input.
  public eventsProcesed: Array<ICalendarItem> = [];

  // Eventos del día seleccionado
  public eventsDay: Array<ICalendarItem> = [];

  constructor(
    public translate: TranslateService,
    public router: Router,
    public renderer: Renderer2
  ) {
    super(translate);
  }

  ngOnInit() {

    this.subscriptions.push(this.loadingEvents$.subscribe(v => {
      if (v !== this.loadingEvents) {
        this.loadingEvents = v;
      }
    }));

    this.subscriptions.push(this.events$.subscribe(e => {

      this.eventsProcesed = [];
      if (e) {
        e.forEach(b => {

          const dateStart = moment(b.startDate);
          const dateEnd = moment(b.endDate);
          let tempDay = dateStart;

          while (tempDay >= dateStart && tempDay <= dateEnd) {
            const item: ICalendarItem = {
              date: tempDay.format('YYYY-MM-DD').toString(),
              color: b.color,
              endDate: b.endDate,
              startDate: b.startDate,
              title: b.title,
              titleCategory: b.titleCategory,
              url: b.url,
              extra: b.extra
            };
            this.eventsProcesed.push(item);
            tempDay = tempDay.add(1, 'days');
          }
        });
      }

      this.refresh();

      // Para mostrar los eventos del día al currentizar los eventos (por un cambio de mes, por ejemplo)
      if (this.calendar) {
        this.eventsDay = this.eventsProcesed
          .filter(ev => moment(ev.date).format('DD') === this.calendar.activeDate.format('DD'));
      }

    }));

  }

  /**
   * Después de que se renderice el calendario seleccionamos el día de hoy.
   */
  ngAfterViewInit() {
    this.today();

    // Para capturar los eventos de los botones de cambio de mes
    const prevButton = document.querySelectorAll('.mat-calendar-previous-button');
    const nextButton = document.querySelectorAll('.mat-calendar-next-button');

    Array.from(prevButton).forEach(button => {
      this.renderer.listen(button, 'click', (event) => {
        this.previousClicked();
      });
    });

    Array.from(nextButton).forEach(button => {
      this.renderer.listen(button, 'click', (event) => {
        this.nextClicked();
      });
    });

    // Para actualizar la fecha al navegar por el select de cabecera
    this.subscriptions.push(this.calendar.monthSelected.subscribe(v => {
      this.selectedDate = v;
      this.dateChanged();
    }));
    this.subscriptions.push(this.calendar.yearSelected.subscribe(v => {
      this.selectedDate = v;
      this.dateChanged();
    }));
  }

  /**
   * Para controlar cuando cambiamos la fecha del calendario.
   */
  public dateChanged() {
    this.calendar.activeDate = this.selectedDate;
    this.paintEvents();
    this.dateSelected.emit(this.selectedDate);
  }

  /**
   * Para movernos a hoy.
   */
  public today() {
    this.selectedDate = moment();
    this.dateChanged();
  }

  /**
   * Para obtener los eventos de un dia en concreto seleccionado en el calendario.
   */
  public paintEvents() {
    const dayOrigin = this.calendar.activeDate.format('DD').toString();
    this.eventsDay = this.eventsProcesed.filter(e => moment(e.date).format('DD').toString() === dayOrigin);
  }

  /**
   * Para obtener la fecha seleccionada o la current en su defecto.
   */
  public getValue() {
    if (this.calendar && this.calendar.activeDate) {
      return this.calendar.activeDate.format('YYYY-MM-DD').toString();
    } else {
      return moment().format('YYYY-MM-DD').toString();
    }
  }

  /**
   * Para ver el mes anterior.
   */
  public previousClicked() {
    if (this.calendar && this.calendar.activeDate) {
      const prevMoment = moment(this.selectedDate).add(-1, 'month');
      this.selectedDate = prevMoment;
      this.dateChanged();
    }
  }

  /**
   * Para controlar ver el siguiente mes.
   */
  public nextClicked() {
    if (this.calendar && this.calendar.activeDate) {
      const nextMoment = moment(this.selectedDate).add(1, 'month');
      this.selectedDate = nextMoment;
      this.dateChanged();
    }
  }

  /**
   * Para marcar un estilo a los dias con salidas.
   */
  public dateClass = (d: moment.Moment) => {
    if (this.eventsProcesed && this.eventsProcesed.length) {
      const result = this.eventsProcesed.find(e => moment(e.date).format('DD').toString() === d.format('DD').toString());
      if (result) {
        return 'event-day';
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  /**
   * Para forzar un refresco de los datos.
   */
  public refresh() {
    if (this.calendar) {
      this.calendar.updateTodaysDate();
    }
  }

  goToEvent(event) {
    this.router.navigateByUrl('/' + event.url);
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import * as CoreRoutingModuleBase from './../../base/core/core-routing.module';

const routes: Routes = CoreRoutingModuleBase.routes;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule],
  // providers: [AuthGuard]
})
export class CoreRoutingModule {

}

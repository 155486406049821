import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class CookiesService {

  public showCookiesConsent = new Subject<boolean>();
  public showCookiesConsent$ = this.showCookiesConsent.asObservable();

  constructor() {
  }

}

import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CoreService } from "../../core.service";
import { SharedService } from "./../../../shared/shared.service";
import { AuthService } from "@web/base/auth/auth.service";
import { Router } from "@angular/router";
import { BaseComponent } from '@web/base/shared/components/base/base.component';

@Component({
  selector: "web-banners-sidenav",
  templateUrl: "./banners-sidenav.component.html",
  styleUrls: ["./banners-sidenav.component.scss"]
})
export class BannersSidenavComponent extends BaseComponent implements OnInit {

  public banners: any;

  constructor(
    translate: TranslateService,
    private coreService: CoreService,
    private sharedService?: SharedService,
    private authService?: AuthService,
    public router?: Router) {
    super(translate);
  }

  ngOnInit() {
    this.subscriptions.push(this.coreService.bannersSidenav$.subscribe(res => {
      if (res) {
        this.banners = res;
      }
    }));
  }

  handleToBanner(url) {
    if (this.authService.user && (this.authService.user.fhn || this.authService.user.observatories.length >= 0)) {
      var windowReference = window.open();  // Para que funcione en Safari hay que crear el elemento ANTES de una llamada asíncrona, y luego asignarle la url
      this.sharedService.loadURLBanner(url, this.authService.user.email).subscribe(res => {
        if (res) {
          windowReference.location = res['result'];
          windowReference.document.title = 'vg-tirant';
          // const link = document.createElement('a');
          // link.title = 'vg-tirant';
          // link.href = res['result'];
          // link.target = '_blank';
          // link.download = 'true';
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);
        }
      });
    } else {
      if (url.indexOf('tirant') !== -1) {
        this.router.navigateByUrl('p/112514/base-de-datos-tirant-lo-blanch');
      } else {
        this.router.navigateByUrl('p/112515/biblioteca');
      }
    }
  }

}

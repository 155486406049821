import { CoreService } from './../core.service';
import { SigninService } from './dialogs/signin/signin.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '@web/base/shared/components/base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { ScrollDispatcher } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { NgModelGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { HeaderService } from './header.service';
import { IResponse } from '@web/base/shared/models/response.model';
import { DomSanitizer } from '@angular/platform-browser';
import { Constants } from '@web/base/shared/constants.class';

@Component({
  selector: 'web-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {

  public HEADER_HEIGHT = ConstantsProject.HEADER_HEIGHT;

  public scrollOffset;

  public searchBarActive = false;
  public isMobile: boolean;

  form: UntypedFormGroup;
  resultSearch;
  MIN_LENGTH_TO_SEARCH = Constants.MIN_LENGTH_TO_SEARCH;

  environment = environment;

  @HostListener('window:scroll', ['$event'])
  onScroll($event: any) {
    this.scrollOffset = $event.srcElement.children[0].scrollTop;
  }

  constructor(
    translate: TranslateService,
    public coreService: CoreService,
    public authService: AuthService,
    public signinService: SigninService,
    private sc: ScrollDispatcher,
    public router: Router,
    public headerService: HeaderService,
    public sanitizer: DomSanitizer) {
    super(translate);
  }

  ngOnInit() {
    this.subscriptions.push(this.coreService.isMobile$.subscribe((val) => this.isMobile = val));

    this.form = new UntypedFormGroup({
      searchText: new UntypedFormControl(null),
    });

    this.form.controls['searchText'].valueChanges.pipe(
      debounceTime(500)
    ).subscribe(v => {
      if (v && v.length >= this.MIN_LENGTH_TO_SEARCH) {
        this.getTranslations(['search']).then(() => {
          this.headerService.loadSearch(v).subscribe((res: IResponse) => {
            if (this.form.controls['searchText'].value) {
              this.resultSearch = res.result.items.map(i => {
                if (i.files) {
                  const image = i.files.find(f => f.name === 'image');
                  i.image = image && image.url ? image.url : null;
                }
                i.category = this.translations['search']['searchCategories'][i.typeSearch];
                return i;
              });
            }
          });
        });
      } else {
        this.resultSearch = null;
      }
    });
  }

  openMenu() {
    this.coreService.setSidenavStatus(true);
  }

  // Marcos - 30/03/2022 - Esto ahora se hace en el html.
  // openSubscriber() {
  //   this.router.navigateByUrl('/informacion/suscripciones');
  // }

  goToUrl(url) {
    this.resultSearch = null;
    this.form.controls['searchText'].reset();

    // Marcos - 30/03/2022 - Esto ahora se hace en el html.
    // this.router.navigateByUrl(url);
  }

  searchAdvanced() {
    if (this.form.controls['searchText'].value) {
      this.router.navigateByUrl('/buscador/' + encodeURIComponent(this.form.controls['searchText'].value));
      this.resultSearch = null;
      this.form.controls['searchText'].reset();
    }
  }

}

import { Store } from '@ngrx/store';
import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import * as fromApp from '../../../../store/app.reducers';
import * as AuthActions from '../../../../auth/store/auth.actions';
import { AuthService } from '../../../../auth/auth.service';
import { RememberPasswordService } from '../remember-password/remember-password.service';
import { BaseComponent } from '@web/base/shared/components/base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { Router, ActivatedRoute } from '@angular/router';
import { CoreService } from '@web/base/core/core.service';
import { Template } from '@web/base/core/template/template.model';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'web-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent extends BaseComponent implements OnInit {

  @ViewChild('password', { static: true }) password: ElementRef;

  public form: UntypedFormGroup;
  public queryParams;

  // Para redirigir a suscripciones
  subscriptionType;
  subscriptionID;
  // Para redirigir a cursos
  baseURL;
  courseID;

  constructor(
    translate: TranslateService,
    private store: Store<fromApp.AppState>,
    public authService: AuthService,
    public rememberPasswordService: RememberPasswordService,
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public coreService: CoreService
  ) {
    super(translate);
    this.authService.errorLogin = '';
  }

  ngOnInit() {

    if (this.queryParams) {
      // recogemos los valores de la suscripción elegida por el usuario
      this.subscriptionType = this.queryParams.type;
      this.subscriptionID = this.queryParams.id;
      // recogemos los valores de la suscripción elegida por el usuario
      this.baseURL = this.queryParams.base;
      this.courseID = this.queryParams.courseID;

      this.subscriptions.push(this.coreService.templateLoaded$.subscribe((v: Template) => {
        if (v.result.user && this.subscriptionType) {
          // Sí tiene parametros significa que viene de la suscripciones
          // lo llevamos a la ventana de nuevo servidor.
          this.router.navigate(['/usuarios/suscripcion', this.subscriptionType, this.subscriptionID]);
          this.subscriptionType = null;
          this.subscriptionID = null;
        }
        if (v.result.user && this.courseID) {
          // Sí tiene parametros de cursos abrimos la url
          let urlFinal = this.baseURL.concat([this.courseID]);
          if (this.queryParams.inscription) {
            urlFinal = urlFinal.concat('directo');
          }
          this.router.navigate(urlFinal);
          this.courseID = null;
        }
      }));
    }

    this.getTranslations(['core']);

    this.form = new UntypedFormGroup({
      username: new UntypedFormControl(null, Validators.compose([
        Validators.required,
        Validators.minLength(1), Validators.maxLength(120)
      ])),
      password: new UntypedFormControl(null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(60)]))
    });
  }

  openRememberPasswordDialog() {
    this.rememberPasswordService.openDialog();
  }

  login() {
    if (this.form.status === 'VALID') {
      this.store.dispatch(new AuthActions.TrySignin(this.form.value));
    }
  }

  linkRegister() {
    this.router.navigate(['usuarios', 'registro'], this.queryParams);
  }

  showPass() {
    this.password.nativeElement.type = 'text';
  }

  hidePass() {
    this.password.nativeElement.type = 'password';
  }
}

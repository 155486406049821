import { Injectable } from '@angular/core';
import { URL, URL_WEB } from '../../../environments/environment';

import { CoreService } from '../core/core.service';
import { HttpClient } from '@angular/common/http';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class AuthService {

  errorLogin: string;

  public user: {
    userWebID: number;
    name: string;
    fhn: number;
    journalCunal: number;
    status: number;
    observatories: Array<number>;
    email: string;
    documentNumberAddressMain: string;
    checkFHN: number;
    isSubscriber: number;
    isIncomplet: number;
  };

  public loggedIn = new BehaviorSubject<boolean>(true);
  public loggedIn$ = this.loggedIn.asObservable();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private coreService: CoreService,
    private http: HttpClient,
  ) {

    this.coreService.templateLoaded$
      /*
      .pipe(
        take(1)
      )
      */
      .subscribe(data => {

        if (data) {
          this.user = data.result.user;

          if (this.user) {
            if (this.user.status === 1) {
              this.loggedIn.next(true);
              // Si el usuario no tiene los datos correctos lo enviamos al editar
              if (this.user.isIncomplet === 1) {
                // this.router.navigate(['usuarios/editar'], { queryParams: { incomplet: 1 } });
                if (window.location.href !== URL_WEB + 'usuarios/editar?incomplet=1') {
                  window.location.href = URL_WEB + 'usuarios/editar?incomplet=1';
                }
              }
              // Si el usuario no esta activo, no permitimos su login
            } else {
              this.logout();
            }

          } else {
            localStorage.removeItem(ConstantsProject.TOKEN_KEY);

            this.loggedIn.next(false);
          }
        }
      });
  }

  // isLoggedIn(): boolean {
  isLoggedIn(): Observable<boolean> {
    // if (isPlatformBrowser(this.platformId)) {
    //   if (localStorage.getItem(ConstantsProject.TOKEN_KEY)) {
    //     return true;
    //   }
    //   return false;
    // }
    // return false;
    return this.loggedIn$;
  }

  logout() {
    localStorage.removeItem(ConstantsProject.TOKEN_KEY);
    this.coreService.loadTemplate();
  }

  rememberPass(data) {
    const url = `${URL}/users-web/remember-password`;
    return this.http.post(url, data);
  }
}

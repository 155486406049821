import { UntypedFormControl, AbstractControl } from '@angular/forms';
import moment from 'moment';

// El null es correcto y el true es error.
export function validateDate(a: UntypedFormControl) {
  if (!isDate(a.value)) {
    return {
      errInvalidDate: true
    };
  } else {
    return null;
  }
}

export function validateDateOrNull(a: UntypedFormControl) {
  if (!a.value) {
    return null;
  } else if (!isDate(a.value)) {
    return {
      errInvalidDate: true
    };
  } else {
    return null;
  }
}

export function validateUrl(a: UntypedFormControl) {
  // tslint:disable-next-line:max-line-length
  if (!a.value || /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})$/.test(a.value)) {
    return null;
  }
  return {
    errInvalidUrl: true
  };
}

export function validateYearsOld(a: UntypedFormControl) {
  if (isDate(a.value)) {
    const yearsOld = moment().diff(moment(a.value), 'years');
    if (yearsOld < 18 || yearsOld > 150) {
      return { errorYearsOld: true };
    }
  }
  return null;
}

export function validateDNI(c: UntypedFormControl) {
  if (!c.value || !c.value.length) {
    return {
      errorDNI: true
    };
  }
  const letras = 'TRWAGMYFPDXBNJZSQVHLCKE';

  if (c.value.length !== 9) {
    return {
      errorDNI: true
    };
  } else {
    let letra = c.value.substr(8, 1);
    letra = letra.toUpperCase();
    let dni = c.value.substr(0, 8);
    dni = dni.toUpperCase();

    dni = dni.replace('X', '0');
    dni = dni.replace('Y', '1');
    dni = dni.replace('Z', '2');

    dni -= parseInt((dni / 23) + '', 10) * 23;
    if (letras.charAt(dni) !== letra) {
      return {
        errorDNI: true
      };
    } else {
      return null;
    }
  }
}

export function validateCIF(cif: UntypedFormControl) {
  if (!cif.value || !cif.value.length) {
    return {
      errorCIF: true
    };
  }

  const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
  const digits = cif.value.substr(1, cif.value.length - 2);
  const letter = cif.value.substr(0, 1);
  const control = cif.value.substr(cif.value.length - 1);
  let sum = 0;
  let i;
  let digit;

  if (!letter.match(/[A-Z]/)) {
    return {
      errorCIF: true
    };
  }
  for (i = 0; i < digits.length; ++i) {
    digit = parseInt(digits[i], 10);

    if (isNaN(digit)) {
      return {
        errorCIF: true
      };
    }
    if (i % 2 === 0) {
      digit *= 2;
      if (digit > 9) {
        digit = digit - 9;
      }
      sum += digit;
    } else {
      sum += digit;
    }
  }
  sum %= 10;
  if (sum !== 0) {
    digit = 10 - sum;
  } else {
    digit = sum;
  }

  if (letter.match(/[ABEH]/)) {
    if (String(digit) === control) {
      return null;
    } else {
      return {
        errorCIF: true
      };
    }
  }

  if (letter.match(/[NPQRSW]/)) {
    if (letters[digit] === control) {
      return null;
    } else {
      return {
        errorCIF: true
      };
    }
  }

  if (String(digit) === control || letters[digit] === control) {
    return null;
  } else {
    return {
      errorCIF: true
    };
  }
}

export function validatePassport(c: UntypedFormControl) {
  if (!c.value || /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/.test(c.value)) {
    return null;
  } else {
    return {
      errorPassport: true
    };
  }
}

export function validateNumber(c: UntypedFormControl) {
  if (!c.value || /^\d+(\.\d{1,3})?$/.test(c.value)) {
    return null;
  } else {
    return {
      errorNumber: true
    };
  }
}

export function validateInt(c: UntypedFormControl) {
  if (!c.value || /^\d+$/.test(c.value)) {
    return null;
  } else {
    return {
      errorInt: true
    };
  }
}

export function validateMinDate(date: string) {
  return (c: UntypedFormControl): { [key: string]: boolean } | null => {
    if (c.value && moment(c.value).diff(moment(date, 'YYYY-MM-DD')) < 0) {
      return {
        errorMinDate: true
      };
    }
    return null;
  };
}

export function validateMaxDate(date: string) {
  return (c: UntypedFormControl): { [key: string]: boolean } | null => {
    if (c.value && moment(c.value).diff(moment(date, 'YYYY-MM-DD')) > 0) {
      return {
        errorMaxDate: true
      };
    }
    return null;
  };
}

export function isDate(value) {
  if (typeof value === 'string') {
    // Anterior sistema falla en las facturas
    // const iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
    // if (value === null || value === undefined) {
    //     return false;
    // }
    // return iso8601.test(value);

    if (value === null || value === undefined) {
      return false;
    }
    return moment(value).isValid();
  } else if (moment.isMoment(value)) {
    return value.isValid();
  } else {
    return false;
  }
}

export function validatePhone(c: UntypedFormControl) {
  if (!c.value || /^[\s|\-|\.]?[6|7|8|9][\s|\-|\.]?([0-9][\s|\-|\.]?){8}$/.test(c.value)) {
    return null;
  } else {
    return {
      isNotPhone: true
    };
  }
}

export function validateMobile(c: UntypedFormControl) {
  if (!c.value || /^[\s|\-|\.]?[6|7|8|9][\s|\-|\.]?([0-9][\s|\-|\.]?){8}$/.test(c.value)) {
    return null;
  } else {
    return {
      isNotMobile: true
    };
  }
}

export function validateEmail(c: UntypedFormControl) {
  if (!c.value || /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(c.value)) {
    return null;
  } else {
    return {
      isNotEmail: true
    };
  }
}

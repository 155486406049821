<div [formGroup]="form" class="edit material-shadow">

  <mat-horizontal-stepper class="purchase-stepper">
    <mat-step>

      <div *ngIf="!addresses.length" class="no-address-block">

        <p class="clear-address" (click)="clearAddress()">{{ 'purchases.clearAddress' | translate }}</p>

        <mat-form-field>
          <mat-label>{{'userAddresses.ttitle' | translate}}</mat-label>
          <input formControlName="title" matInput placeholder="{{'userAddresses.ttitle' | translate}}" required>
          <mat-error *ngIf="form.controls['title'].hasError('required')"> {{'general.required' | translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{'userAddresses.entity' | translate}}</mat-label>
          <input formControlName="entity" matInput placeholder="{{'userAddresses.entity' | translate}}" required>
          <mat-error *ngIf="form.controls['entity'].hasError('required')"> {{'general.required' | translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{'userAddresses.address' | translate}}</mat-label>
          <input formControlName="address" matInput placeholder="{{'userAddresses.address' | translate}}" required>
          <mat-error *ngIf="form.controls['address'].hasError('required')">{{'general.required' | translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{'userAddresses.additionalAddress' | translate}}</mat-label>
          <input formControlName="address2" matInput placeholder="{{'userAddresses.additionalAddress' | translate}}">
        </mat-form-field>

        <div class="layout-2-columns-xs">
          <mat-form-field>
            <mat-label>{{'userAddresses.cp' | translate}}</mat-label>
            <input formControlName="cp" matInput placeholder="{{'userAddresses.cp' | translate}}" required>
            <mat-error *ngIf="form.controls['cp'].hasError('required')">{{'general.required' | translate}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'userAddresses.locality' | translate }}</mat-label>
            <mat-select placeholder="{{ 'userAddresses.locality' | translate }}" formControlName="localityID" required>
              <mat-option [value]="locality.localityID" *ngFor="let locality of localities">{{locality.name}}
              </mat-option>
            </mat-select>
            <mat-error>{{'general.required' |translate}}</mat-error>
          </mat-form-field>
        </div>

        <div class="layout-3-columns-sm">
          <mat-form-field>
            <mat-label>{{'userAddresses.province' | translate}}</mat-label>
            <input formControlName="nameProvince" matInput placeholder="{{'userAddresses.province' | translate}}">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'userAddresses.community' | translate}}</mat-label>
            <input formControlName="nameCommunity" matInput placeholder="{{'userAddresses.community' | translate}}">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'userAddresses.country' | translate}}</mat-label>
            <input formControlName="nameCountry" matInput placeholder="{{'userAddresses.country' | translate}}">
          </mat-form-field>
        </div>

        <div class="layout-3-columns-sm">
          <mat-form-field class="document-type-field">
            <mat-label>{{ 'userAddresses.documentType' | translate }}</mat-label>
            <mat-select placeholder="{{ 'userAddresses.documentType' | translate }}" formControlName="documentType"
              required>
              <mat-option [value]="documentType.value" *ngFor="let documentType of documentTypes">
                {{documentType.textReadable}}</mat-option>
            </mat-select>
            <mat-error>{{'general.required' |translate}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'userAddresses.documentNumber' | translate }}</mat-label>
            <input matInput placeholder="{{ 'userAddresses.documentNumber' | translate }}"
              formControlName="documentNumber" required>
            <mat-error *ngIf="form.controls['documentNumber'].hasError('required')">
              {{'general.required' | translate}}</mat-error>
            <mat-error
              *ngIf="!form.controls['documentNumber'].hasError('required') && form.controls['documentNumber'].hasError('errorDNI')  && form.controls['documentType'].value === 'dni'">
              {{'general.isNotDNI' | translate}} </mat-error>
            <mat-error
              *ngIf="!form.controls['documentNumber'].hasError('required') && form.controls['documentNumber'].hasError('errorDNI') && form.controls['documentType'].value === 'nie'">
              {{'general.isNotNIE' | translate}} </mat-error>
            <mat-error
              *ngIf="!form.controls['documentNumber'].hasError('required') && form.controls['documentNumber'].hasError('errorCIF')">
              {{'general.isNotCIF' | translate}} </mat-error>
            <mat-error
              *ngIf="!form.controls['documentNumber'].hasError('required') && form.controls['documentNumber'].hasError('duplicateDocumentNumber')">
              {{'general.duplicateDocumentNumber' | translate}} </mat-error>
          </mat-form-field>
          <mat-form-field class="phone-field">
            <mat-label>{{ 'userAddresses.phone' | translate }}</mat-label>
            <input matInput placeholder="{{ 'userAddresses.phone' | translate }}" formControlName="phone" required>
            <mat-error *ngIf="form.controls['phone'].hasError('required')">{{'general.required' | translate}}
            </mat-error>
            <mat-error
              *ngIf="!form.controls['phone'].hasError('required') && form.controls['phone'].hasError('isNotPhone')">
              {{'general.isNotPhone' | translate}}</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="form.controls['documentType'].value === 'cif'" [class.dir-code]="form.controls['codeDIR'].value">
          <mat-checkbox color="accent" formControlName="codeDIR">{{'userAddresses.codeDIR' | translate }}
          </mat-checkbox>
          <div *ngIf="form.controls['codeDIR'].value" style="margin-top:16px;">
            <mat-form-field>
              <mat-label>{{ 'userAddresses.managingBody' | translate }}</mat-label>
              <input matInput placeholder="{{ 'userAddresses.managingBody' | translate }}"
                formControlName="managingBody" required>
              <mat-error>{{'general.required' |translate}}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'userAddresses.processingUnit' | translate }}</mat-label>
              <input matInput placeholder="{{ 'userAddresses.processingUnit' | translate }}"
                formControlName="processingUnit" required>
              <mat-error>{{'general.required' |translate}}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'userAddresses.accountingOffice' | translate }}</mat-label>
              <input matInput placeholder="{{ 'userAddresses.accountingOffice' | translate }}"
                formControlName="accountingOffice" required>
              <mat-error>{{'general.required' |translate}}</mat-error>
            </mat-form-field>
          </div>
          <mat-form-field>
            <mat-label>{{ 'userAddresses.contractNumber' | translate }}</mat-label>
            <input matInput placeholder="{{ 'userAddresses.contractNumber' | translate }}"
              formControlName="contractNumber">
          </mat-form-field>
        </div>
      </div>

      <ng-container *ngIf="addresses.length">
        <mat-form-field class="address-field">
          <mat-label>{{'purchases.billingAddressSelect' | translate }}</mat-label>
          <mat-select formControlName="userWebAddressID"
            placeholder="{{'purchases.billingAddressSelect' | translate }}">
            <mat-option *ngFor="let a of addresses" [value]="a.userWebAddressID">
              {{a.title}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['userWebAddressID'].hasError('errorLocality')">
            {{'purchases.errorAddressLocality' |translate}}
          </mat-error>
        </mat-form-field>
        <div fxLayoutAlign="start center" fxLayoutGap="4px" class="address-hint">
          <mat-icon>room</mat-icon>
          <span>{{addressSelectedHTML}}</span>
        </div>
      </ng-container>

      <!-- Forma pago  / Cantidad-->
      <div *ngIf="!purchaseData || purchaseData.total > 0" fxLayout.xs="column" fxLayoutAlign="start center"
        fxLayoutGap="24px" fxLayoutGap.xs="0" style="margin-top:16px">
        <mat-form-field>
          <mat-label>{{ 'purchases.wayPay' | translate }}</mat-label>
          <mat-select placeholder="{{ 'purchases.wayPay' | translate }}" formControlName="wayPayID">
            <mat-option [value]="wp.wayPayID" *ngFor="let wp of waysPays">{{wp.name}}
            </mat-option>
          </mat-select>
          <mat-error>{{'general.required' |translate}}</mat-error>
        </mat-form-field>

        <div [fxShow]="form.controls['wayPayID'].value === WAYPAYDOMICILIED" class="layout-3-columns-sm">
          <web-iban-input #iban [required]="form.controls['wayPayID'].value === WAYPAYDOMICILIED" [check]="checkIban$"
            formControlName="iban"></web-iban-input>

          <mat-form-field>
            <mat-label>{{ 'general.bic' | translate }}</mat-label>
            <input matInput formControlName="bic" placeholder="{{ 'general.bic' | translate }}" />
            <mat-error>{{ "general.required" | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'userAddresses.entityBank' | translate }}</mat-label>
            <input matInput formControlName="entityBank" placeholder="{{ 'userAddresses.entityBank' | translate }}" />
            <mat-error>{{ "general.required" | translate }}</mat-error>
          </mat-form-field>
        </div>

      </div>

      <!-- Observaciones / Cod. descuento -->
      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start start" fxLayoutGap.gt-sm="24px"
        style="margin-top:12px;">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{'purchases.observations' | translate}}</mat-label>
          <textarea matInput formControlName="observations" style="height:44px"></textarea>
        </mat-form-field>
        <mat-form-field class="discount-field" appearance="outline" floatLabel="always">
          <mat-label>{{'purchases.discount' | translate}}</mat-label>
          <input formControlName="discount" matInput>
          <mat-hint>{{'purchases.discountHint'|translate}}</mat-hint>
          <mat-error *ngIf="form.controls['discount'].hasError('codeDiscountIncorrect')">
            {{'purchases.codeDiscountIncorrect' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="purchaseData && purchaseData.total" class="calculated-purchase-price">
        {{'purchases.textImport' | translate: {purchase: item.title, price: purchaseData.total|
        currency:'EUR':'symbol':'1.2-2'} }}
      </div>

      <mat-checkbox formControlName="conditions" class="conditions-check">
        {{'general.checkConditions01'|translate}} <a
          (click)="$event.preventDefault(); openDataProtection()">{{'general.checkConditions02'|translate}}</a>
      </mat-checkbox>

      <button mat-flat-button color="accent" matStepperNext [disabled]="form.invalid">{{'general.continue' |
        translate}}</button>

    </mat-step>
    <mat-step *ngIf="purchaseData">
      <div class="summary">
        <h3>{{'purchases.summary' | translate}}</h3>

        <div class="table-container no-shadow no-border">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Nombre</th>
                <th class="text-right">{{'purchases.quantity' | translate}}</th>
                <th class="text-right">{{'purchases.price' | translate}}</th>
                <th class="text-right">{{'purchases.subtotal' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{item.title}}</td>
                <td class="text-right">{{purchaseData.quantity}}</td>
                <td class="text-right">
                  {{purchaseData.price | currency:'EUR':'symbol':'1.2-2'}}</td>
                <td class="text-right">
                  {{purchaseData.base | currency:'EUR':'symbol':'1.2-2'}}</td>
              </tr>
              <!-- <tr *ngIf="purchaseData.subscrip">
                <td>{{'purchases.subscrip' | translate}}
                  ({{form.controls['subscrip'].value}})</td>
                <td></td>
                <td class="text-right">
                  -{{purchaseData.discount | currency:'EUR':'symbol':'1.2-2'}}</td>
                <td class="text-right">
                  {{purchaseData.subtotal | currency:'EUR':'symbol':'1.2-2'}}</td>
              </tr> -->
              <tr *ngIf="purchaseData.discount">
                <td>{{'purchases.discountApplied' | translate}}
                  ({{form.controls['discount'].value}})</td>
                <td></td>
                <td class="text-right">
                  -{{purchaseData.discount | currency:'EUR':'symbol':'1.2-2'}}</td>
                <td class="text-right">
                  {{purchaseData.subtotal | currency:'EUR':'symbol':'1.2-2'}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>{{'purchases.taxes' | translate}}</td>
                <td></td>
                <td class="text-right">{{purchaseData.vatPercentage}}%</td>
                <td class="text-right">
                  {{purchaseData.vat | currency:'EUR':'symbol':'1.2-2'}}</td>
              </tr>
              <tr class="total">
                <td colspan="3" class="text-right">{{'purchases.total' | translate}}:</td>
                <td class="text-right">
                  {{purchaseData.total | currency:'EUR':'symbol':'1.2-2'}}</td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div *ngIf="addressSelected" class="width-100" fxLayout.xs="column" fxLayoutAlign="space-between stretch"
          fxLayoutGap="24px" fxLayoutGap.xs="12px" style="margin-top:24px;">

          <div class="address">
            <div class="title">{{'purchases.billingAddress' | translate }}:</div>
            <div><strong>{{addressSelected.title}}</strong></div>
            <div>{{addressSelected.address}}, <span *ngIf="addressSelected.address2">{{addressSelected.address2}}</span>
            </div>
            <div>{{addressSelected.cp}}, {{addressSelected.nameLocality}} <span *ngIf="addressSelected.nameProvince">(
                {{addressSelected.nameProvince}} )</span></div>
          </div>

          <div class="more-info">
            <div class="document-type">
              <strong>{{addressSelected.documentType}}</strong> :
              {{addressSelected.documentNumber}}
            </div>
            <div class="waypay" *ngIf="wayPaySelected">
              <strong>{{'purchases.wayPay' | translate }}</strong> : {{wayPaySelected.name}}
            </div>
          </div>
        </div>

        <div fxLayoutAlign="start center" fxLayoutGap="10px" class="actions">
          <button mat-flat-button class="btn-cancel" matStepperPrevious>{{'general.back' | translate}}</button>
          <web-loading-button matStepperNext [loading]="isSaving" [msg]="'purchases.send' | translate"
            [loadingMsg]="'purchases.send' | translate" (click)="submit();">
          </web-loading-button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>

</div>
<div class="header" fxLayoutAlign="space-between center" fxLayoutGap="24px">
  <h2 mat-dialog-title *ngIf="data.popup.title">{{ data.popup.title }}</h2>
  <div class="_close">
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content>
  <div class="image" *ngIf="data.popup.img">
    <img [src]="data.popup.img" class="img-responsive center-block" [alt]="data.popup.title">
  </div>
  <div *ngIf="data.popup.text" class="content" [innerHTML]="data.popup.text | safeHtml"></div>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end center" fxLayoutGap="5px">
  <button mat-raised-button color="accent" [disabled]="!functionalCookiesConsent" (click)="hide7days()">{{
    'core.popup.hide7days' | translate }}</button>
  <button mat-raised-button color="primary" [disabled]="!functionalCookiesConsent" (click)="hide4ever()">{{
    'core.popup.hide4ever' | translate }}</button>
</mat-dialog-actions>